<template>
  <div v-if="dataLoaded">
    <pdf-download
      :full-screen="true"
      :file-name="fileName"
      :itemPerPage="itemPerPage"
      @setItemPerPage="setItemPerPage"
    >
      <v-container
        v-for="(products, i) in productsPerPage"
        :key="i"
        class="pdf overflow-hidden"
        :class="{ 'html2pdf__page-break': true }"
        style="position: relative; height: 34.5cm"
      >
        <div v-if="order">
          <!-- v-if="showHeader" -->
          <OrderHeader
            :order="order"
            :date="pdfData.date"
            :hour="pdfData.hour"
            :addressTitle="pdfData.addressTitle"
            :address="pdfData.address"
            :isFirstPage="i === 0"
          />
          <!-- v-if="showDeliveryInfo" -->
          <v-row
            v-if="i === 0 && pdfData.deliveryInfo.length"
            style="max-width: 60%; margin: 0 0 5px 0"
          >
            <div v-for="info in pdfData.deliveryInfo" :key="info" v-text="info" />
          </v-row>
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 18px;
            "
          >
            <div
              class="bold"
              :style="`${
                order.serveInfo && order.serveInfo !== 'no-mention' && !i
                  ? ''
                  : 'visibility: hidden'
              }`"
            >
              {{ $t(`delivery.servingTypes.${order.serveInfo}`) }}
            </div>
            <span v-if="order.orderNum"
              ><span class="font-weight-bold">{{ $t('order.num') }}</span
              >: {{ order.orderNum }}</span
            >
          </div>
          <!-- v-if="showCustomerDetails" -->
          <OrderCustomerDetails v-if="i == 0" :title="title" :pdfData="pdfData" />
          <!-- payment type -->
          <!-- <span>
            <span class="bold">צורת תשלום: </span>
            {{ pdfData.payment }}
          </span> -->
          <div v-if="i !== 0" style="min-height: 9vh" />

          <OrderTable v-if="showTable" :products="products" />

          <OrderTableFooter
            v-if="isLastPage(i)"
            :menu="order.menu"
            :orderTotalPrice="orderTotalPrice"
          />
          <div
            v-if="isLastPage(i) && order.orderNum"
            style="display: flex; justify-content: end"
          >
            <span
              ><span style="font-weight: bold"
                >{{ $t('order.numOfBoxes') }}: </span
              >{{ countBoxedProducts(order) }}</span
            >
          </div>
          <v-row
            v-if="isLastPage(i)"
            style="
              min-height: 7vh;
              max-width: 60%;
              margin: -25px 0 10px 0;
              flex-direction: column;
              gap: 10px;
            "
            :style="`${order.orderNum ? '' : 'margin-top: 0'}`"
          >
            <div v-text="order.infoOnPayment" />
            <div v-text="pdfData.menuInfo" />
          </v-row>

          <OrderFooter :pageCounter="productsPerPage.length > 1 ? i + 1 : 0" />
        </div>
      </v-container>
    </pdf-download>
  </div>
</template>

<script>
import Times from '@/helpers/times'
import PdfDownload from '@/components/PdfDownload'
import OrderFooter from '../components/PDF/OrderFooter.vue'
import OrderHeader from '../components/PDF/OrderHeader.vue'
import OrderTableFooter from '../components/PDF/OrderTableFooter.vue'
import OrderTable from '../components/PDF/OrderTable.vue'
import OrderCustomerDetails from '../components/PDF/OrderCustomerDetails.vue'

export default {
  name: 'OrderPdf',
  components: {
    PdfDownload,
    OrderFooter,
    OrderHeader,
    OrderTableFooter,
    OrderTable,
    OrderCustomerDetails,
  },
  data() {
    return {
      title: this.$t('menu.title'),
      itemPerPage: 30,
      dataLoaded: false,
    }
  },
  methods: {
    countBoxedProducts(order) {
      const products = order.menu.products
      return products.reduce((acc, curr) => {
        const { product, quantity } = curr
        const hasBox = product?.box
        return hasBox ? acc + quantity : acc
      }, 0)
    },
    setItemPerPage(itemPerPage) {
      this.itemPerPage = itemPerPage
    },
    createNewPage(index, itemPerPage) {
      // lastPageItems,
      //  firstPageItems,
      //is first page || if end of other page
      // if (
      //   index === firstPageItems ||
      //   (index - firstPageItems) % itemPerPage === 0
      // ) {
      //   return 1
      // }
      if (index && (itemPerPage === index || index % itemPerPage === 0)) {
        return 1
      }
      return 0
    },
    isLastPage(i) {
      return i === this.productsPerPage.length - 1
    },
    parserNewLine(string) {
      return string.split('\n')
    },
  },
  computed: {
    showHeader() {
      return true
    },
    showDeliveryInfo() {
      return true
    },
    showCustomerDetails() {
      return true
    },
    showTable() {
      return true
    },

    pdfData() {
      const { menuInfo, info, delivery, paymentData, client } = this.order
      const data = {
        date:
          `${Times.dateByDot(delivery?.day)} - ${this.$t('date.day')} ` ?? '',
        hour: delivery?.startHour ?? '',
        address: delivery.address ? this.parserNewLine(delivery.address) : [],
        fullname: client?.fullname ?? '',
        phone: client?.phone ?? '',
        payment: paymentData?.type
          ? this.$t(`payment.${paymentData?.type}`)
          : '',
        userId: client?.userId ?? '',
        deliveryInfo: delivery.info ? this.parserNewLine(delivery.info) : [],
        info,
        menuInfo,
      }
      if (delivery.type === 'self') {
        data['addressTitle'] = this.$t('delivery.collectFromShopAddress')
      } else if (delivery.type === 'delivery') {
        data['addressTitle'] = `${this.$t('delivery.index')}!`
      }
      return data
    },
    order() {
      let order = null
      const menuId = this.$route.query?.menu
      if (!menuId) {
        order = this.$store.getters.order
      } else {
        const bid = this.$store.getters.bid
        if (!bid) return

        const menu = bid.menus.find(m => m._id == menuId)
        bid.menu = menu
        order = bid
      }
      if (!order) return
      return order
    },
    allProducts() {
      return this.order?.menu?.products || []
    },

    productsPerPage() {
      // const itemPerPage = 70
      // lastPageItems,
      // firstPageItems,
      // const firstPageItems = this.pdfData.deliveryInfo ? 28 : 28
      // const firstPageItems = 24
      const lastPageItems = 10
      const products = [[]]
      let counter = 0

      for (let i = 0; i < this.allProducts.length; i++) {
        const product = this.allProducts[i]
        const count = this.createNewPage(i, this.itemPerPage)
        if (count) {
          counter = counter + count
          products.push([])
        }
        products[counter].push(product)
        // if is last page
        // if (
        //   i === this.allProducts.length - 1 &&
        //   products[counter].length > lastPageItems
        // ) {
        //   products.push([])
        // }
      }
      return products
    },
    orderTotalPrice() {
      let price = 0
      this.allProducts.forEach(p => (price += p.quantity * p.product.price))
      return price
    },
    fileName() {
      return this.order?.menu?.title
    },
    // paymentMethodText() {
    //   return this.$t(`payment.${this.order.paymentData?.type}`)
    // },
  },
  async beforeCreate() {
    if (this.$route.query?.menu) {
      await this.$store.dispatch('bid/show', {
        id: this.$route.params.id,
        toastOptions: { disabled: true },
      })
    } else {
      await this.$store.dispatch('order/show', {
        id: this.$route.params.id,
        toastOptions: { disabled: true },
      })
    }
    this.dataLoaded = true
  },
}
</script>

<style lang="scss" scoped>
::v-deep.pdf {
  * {
    box-sizing: border-box;
    font-family: 'Assistant';
  }

  table {
    border-collapse: collapse;

    tr {
      border: 3px solid var(--secondaryLight);

      th {
        font-size: smaller !important;
        height: 30px !important;
      }

      td {
        font-size: medium !important;
        height: 26px !important;
      }
    }
  }
}

.pdf {
  direction: rtl;
  min-height: 700px;
  border-collapse: collapse;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 40px 0 40px;
  // min-height: 1304px;
  margin-top: 40px;
}
</style>
