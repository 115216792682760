<template>
  <div>
    <my-dialog
      :title="$t('client.handlerDriverTitle')"
      v-model="openModal"
      :maxWidth="400"
      :persistent="false"
    >
      <template v-slot:content>
        <v-form>
          <v-row>
            <v-text-field
              :label="$t('userDetails.fullName')"
              v-model="form.fullname"
              autofocus
              required
            />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('userDetails.phone')"
              v-model="form.phone"
              autofocus
              required
            />
          </v-row>
          <v-row v-if="err">
            <v-col class="error--text text-center">
              {{ err }}
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn
          class="black--text#860q31h3t"
          color="primary"
          @click.prevent="submit"
          :disabled="!validateForm()"
          >{{ $t('generics.save') }}</v-btn
        >
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from './generics/MyDialog.vue'
import _ from 'lodash'

export default {
  name: 'driver-handler',
  components: { MyDialog },
  props: { value: Boolean, client: Object, isNew: Boolean },
  data() {
    return {
      err: null,
      form: {
        fullname: '',
        phone: '',
      },
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    async submit() {
      if (!this.isNew) {
        await this.$store.dispatch('driver/update', this.form)
      } else {
        await this.$store.dispatch('driver/store', this.form)
      }
      this.openModal = false
    },
    validateForm() {
      return this.form.fullname && this.form.phone
    },
    createErr(msg) {
      this.err = msg
      setTimeout(() => {
        this.err = null
      }, 2000)
    },
  },

  mounted() {
    if (!this.isNew) this.form = _.cloneDeep(this.client)
  },
}
</script>
