<template>
  <div v-if="displayMode !== null">
    <v-row justify="center" class="pt-3 pb-1 primary">
      <v-btn-toggle
        v-model="displayMode"
        tile
        color="deep-purple accent-3"
        group
      >
        <v-btn value="daily"> {{ $t('generics.dailyView') }}</v-btn>
        <v-btn value="dates-range"> {{ $t('generics.dateRange') }}</v-btn>
      </v-btn-toggle>
    </v-row>

    <filter-app-bar
      v-if="displayMode === 'dates-range' && isFilterDefaultValueLoaded"
      :style="{ background: 'primary !important' }"
      @updateStart="updateStart"
      @updateEnd="updateEnd"
      :defaultStart="start"
      :defaultEnd="end"
      :showIsDeliveryDateSwitch="false"
      :defaultFilterObj="defaultFilter"
    />
    <v-row
      v-if="displayMode === 'daily'"
      justify="center"
      align="center"
      class="mb-3 primary"
    >
      <v-btn icon @click="goToPrevDay">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <h2 class="mx-2 text-h5">{{ dateFormatter(start) }}</h2>
      <v-btn icon @click="goToNextDay">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-row>
    <h2 class="text-center text-h5 mt-3" v-if="displayMode === 'dates-range'">
      {{
        !end ? dayBySlash(start) : `${dayBySlash(end)} - ${dayBySlash(start)}`
      }}
    </h2>
    <h1 class="text-center mb-5 mt-3">{{ $t('generics.deliveries') }}</h1>
    <v-container>
      <div v-if="!orders.length" class="center-all mt-15">
        <h3>{{ $t('order.noOrdersShort') }}</h3>
      </div>
      <v-card v-else id="shipments-table" class="pa-0" rounded="lg">
        <v-card-title class="d-flex align-center">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                icon
                :label="$t('generics.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <shipments-table
            :search="search"
            :loading="loading"
            :orders="orders"
          />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import ShipmentsTable from '../components/ShipmentsTable.vue'
import FilterAppBar from '../layouts/FilterAppBar.vue'
import genericsServices from '../services/generics'
import Times from '../helpers/times'
import timeMixins from '../mixins/time'
import FiltersCaching from '@/services/FiltersCaching.js'

export default {
  name: 'shipments-page',
  components: { ShipmentsTable, FilterAppBar },
  mixins: [timeMixins],
  data() {
    return {
      loading: false,
      search: '',
      displayMode: false,
      start: null,
      end: null,
      mounted: false,
    }
  },
  computed: {
    isFilterDefaultValueLoaded() {
      return this.start && this.end
    },
    defaultFilter() {
      const today = dayjs()
      return {
        start: today.format(Times.dateFormat),
        end: today.format(Times.dateFormat),
      }
    },
    orders() {
      const orders = this.$store.getters.orderForDelivery
      return genericsServices.filterOrders(
        orders,
        this.start,
        this.displayMode === 'dates-range' ? this.end : this.start,
        true
      )
    },
  },
  methods: {
    dayBySlash(date) {
      return Times.dayBySlash(date)
    },
    async loadData() {
      this.loading = true
      await this.$store.dispatch('order/shipments-in-date-range', {
        startDate: this.start,
        endDate: this.displayMode === 'dates-range' ? this.end : this.start,
        toastOptions: { disabled: true },
      })
      this.loading = false
    },
    dateFormatter(date) {
      return Times.extractDateString(date)
    },
    getOrderDayString(delivery) {
      return Times.extractDayString(delivery?.day)
    },
    updateStart(v) {
      this.start = v
    },
    updateEnd(v) {
      this.end = v
    },
    goToNextDay() {
      this.start = dayjs(this.start).add(1, 'day').format(Times.dateFormat)
    },
    goToPrevDay() {
      this.start = dayjs(this.start).subtract(1, 'day').format(Times.dateFormat)
    },
  },
  async mounted() {
    const { start, end, displayMode } = FiltersCaching.get({
      type: 'all',
    })
    this.start = start || this.defaultFilter.start
    this.end = end || this.defaultFilter.end
    this.displayMode = displayMode || 'daily'
    await this.loadData()
    this.mounted = true
  },
  watch: {
    displayMode() {
      if (!this.start && this.displayMode === 'daily') {
        this.start = dayjs().format(Times.dateFormat)
      }
    },
    start(value) {
      FiltersCaching.save({ type: 'all', key: 'start', value })
      if (this.mounted) this.loadData()
    },
    end(value) {
      FiltersCaching.save({ type: 'all', key: 'end', value })
      if (this.mounted) this.loadData()
    },
    displayMode(value) {
      FiltersCaching.save({ type: 'all', key: 'displayMode', value })
    },
  },
}
</script>
