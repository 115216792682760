<template>
  <v-simple-table dense v-if="products.length" class="vuetify-table">
    <template v-slot:default>
      <thead>
        <tr>
          <th style="text-align: center !important" class="quantity">
            {{ $t('generics.quantity') }}
          </th>
          <th style="text-align: center !important" class="text-start">
            {{ $t('generics.product') }}
          </th>
          <th style="text-align: end !important" class="price">
            {{ $t('product.price') }}
          </th>
          <th style="text-align: center !important" class="text-start">
            {{ $t('generics.info') }}
          </th>

          <th
            style="text-align: end !important; padding-left: 15px !important"
            class="sum"
          >
            סה"כ
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-row" v-for="(p, i) in products" :id="i" :key="i">
          <template v-if="p.isTitle">
            <td colspan="5">
              {{ p.product.title }}
            </td>
          </template>
          <template v-else>
            <td style="text-align: center !important">{{ p.quantity }}</td>
            <td>{{ p.product.title }}</td>
            <!-- <td>מגש קטן לביבות עדשים ועשבי תיבול - 15 יח' + טחינה</td> -->
            <td style="text-align: end !important" class="price">
              {{ formateNumbers(p.product.price) }} ₪
            </td>
            <td class="p-info" style="font-size: 12px !important">
              {{ p.product.info }}
            </td>
            <!-- <td class="p-info">
                  שקיות צלופן אישיות קיט קפה + עוגיות - אפשר עוגיות חלבי
                </td> -->

            <td
              style="text-align: end !important; padding-left: 15px !important"
              class="sum"
            >
              {{ sum(p) }} ₪
            </td>
          </template>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import { formateNumbers } from '@/helpers/formatter'
export default {
  name: 'OrderTable',
  props: {
    products: Array,
  },
  methods: {
    sum(p) {
      return this.formateNumbers(p.quantity * p.product.price)
    },
    formateNumbers,
  },
}
</script>
<style lang="scss" scoped>
.vuetify-table {
  padding-top: 8px;
}

table {
  table-layout: fixed;
  width: 100%;

  .quantity {
    width: 6%;
  }

  .price {
    width: 12%;
  }

  .sum {
    width: 12%;
  }

  th,
  td {
    padding: 0 6px !important;
    font-size: small !important;
  }

  .p-info {
    width: 15%;
  }
}

.table-row {
  &:nth-child(odd) {
    background-color: var(--grey-highlight);
  }

  .header td {
  }

  // &:last-child {
  //   background-color: var(--light-grey);
  // }
}
</style>
