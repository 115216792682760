<template>
  <div class="not-find">
    <v-container class="d-flex align-center flex-column">
      <v-text-field v-text="$t('generics.notFound')"></v-text-field>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'not-found',
}
</script>
<style lang="scss" scoped>
.not-find {
  margin: 20% auto;
}
</style>
