<template>
  <v-dialog v-model="active" width="500">
    <v-card class="px-10 pb-4">
      <v-card-title class="primary--text d-block text-center">{{
        title
      }}</v-card-title>
      <v-text-field :label="label" v-model="input"></v-text-field>
      <div class="d-flex justify-center">
        <v-btn @click="active = false" color="primary">סגירה</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'text-field-dialog',
  props: {
    model: { type: Boolean, default: true },
    title: { type: String, default: 'Default title' },
    label: { type: String, default: 'Default label' },
    value: String,
  },
  computed: {
    active: {
      get() {
        return this.model
      },
      set(v) {
        this.$emit('model', v)
      },
    },
    input: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  created() {
    this.inputData = this.value
  },
}
</script>

<style></style>
