<template>
  <div>
    <filter-app-bar
      v-if="isFilterDefaultValueLoaded"
      @updateDeliveryDate="updateDeliveryDate"
      @updateStart="updateStart"
      @updateEnd="updateEnd"
      :defaultStart="start"
      :defaultEnd="end"
      :defaultIsDeliveryDateValue="isDeliveryDate"
      :defaultFilterObj="defaultFilter"
      :disabled="!initialDataFetchFinish"
    />
    <v-container v-if="isFilterDefaultValueLoaded">
      <h2 class="text-center text-h5">
        {{
          !end ? dayBySlash(start) : `${dayBySlash(end)} - ${dayBySlash(start)}`
        }}
      </h2>
      <h1 v-if="!notActivated" class="text-center mb-5 mt-3">
        {{ $tc('order.index', 2) }}
      </h1>
      <h1 v-if="notActivated" class="text-center mb-5 mt-3">
        {{ $tc('generics.ordersNotPaidFromWeb') }}
      </h1>
      <v-card v-if="orders.length" id="orders-table" class="pa-0" rounded="lg">
        <v-card-text>
          <orders-table
            :orders="orders"
            @onRowClick="editOrder"
            :notActivated="notActivated"
          />
        </v-card-text>
      </v-card>
      <div v-else class="center-all mt-15">
        <h3>{{ $t('order.noOrders') }}</h3>
      </div>
    </v-container>
    <order-handler
      v-if="orderHandlerDialog"
      v-model="orderHandlerDialog"
      :orderID="chosenOrder"
      :type="-1"
    />
  </div>
</template>
<script>
import dayjs from 'dayjs'
import OrderHandler from '../components/OrderDialog.vue'
import OrdersTable from '../components/OrdersTable.vue'
import FilterAppBar from '../layouts/FilterAppBar.vue'
import genericsServices from '../services/generics'
import Times from '../helpers/times'
import FiltersCaching from '@/services/FiltersCaching.js'

export default {
  name: 'orders',
  components: { OrderHandler, OrdersTable, FilterAppBar },
  props: {
    notActivated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      orderHandlerDialog: false,
      chosenOrder: null,
      start: null,
      end: null,
      isDeliveryDate: null,
      initialDataFetchFinish: false,
      initialDataFetchStart: false,
    }
  },
  computed: {
    isFilterDefaultValueLoaded() {
      return this.start && this.end && this.isDeliveryDate !== null
    },
    orders() {
      const orders = this.$store.getters.orders
      return orders
      // return genericsServices.filterOrders(
      //   orders,
      //   this.start,
      //   this.end,
      //   this.isDeliveryDate
      // )
    },
    defaultFilter() {
      const today = dayjs()
      return {
        start: today.format(Times.dateFormat),
        end: today.add(3, 'day').format(Times.dateFormat),
      }
    },
  },
  methods: {
    async loadData() {
      if (this.initialDataFetchStart) return
      this.initialDataFetchStart = true
      await this.orderIndex()
      this.initialDataFetchFinish = true
    },
    async orderIndex() {
      await this.$store.dispatch('order/index', {
        min: this.start,
        max: this.end,
        notActivated: this.notActivated,
      })
    },
    getOrderDay(delivery) {
      return Times.extractDateString(delivery?.day)
    },
    getOrderDayString(delivery) {
      return Times.extractDayString(delivery?.day)
    },
    itemRowBackground(item) {
      if (item.status === 'done') return 'success'
      else if (item.status === 'waiting') return 'error'
      return 'white'
    },
    editOrder(order) {
      this.chosenOrder = order._id
      this.orderHandlerDialog = true
    },
    updateStart(v) {
      this.start = v
    },
    updateEnd(v) {
      this.end = v
    },
    updateDeliveryDate(v) {
      this.isDeliveryDate = v
    },
    dayBySlash(date) {
      return Times.dayBySlash(date)
    },
  },
  async mounted() {
    const { start, end, isDeliveryDate } = FiltersCaching.get({
      type: 'all',
    })
    this.start = start || this.defaultFilter.start
    this.end = end || this.defaultFilter.end
    this.isDeliveryDate = isDeliveryDate !== undefined ? isDeliveryDate : true
    await this.loadData()
  },
  watch: {
    start(value) {
      FiltersCaching.save({ type: 'all', key: 'start', value })
      if (!this.initialDataFetchFinish) return
      this.orderIndex()
    },
    end(value) {
      FiltersCaching.save({ type: 'all', key: 'end', value })
      if (!this.initialDataFetchFinish) return
      this.orderIndex()
    },
    isDeliveryDate(value) {
      FiltersCaching.save({ type: 'all', key: 'isDeliveryDate', value })
    },
    notActivated() {
      this.orderIndex()
    },
  },
}
</script>
