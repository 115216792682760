<template>
  <v-container>
    <h3 class="text-center mt-10">{{ $t('client.handlerTitleDrivers') }}</h3>
    <v-data-table
      :headers="headers"
      :items="drivers"
      :loading="loading"
      :search="search"
      :items-per-page="20"
      id="orders-table"
      class="mt-2 app-table"
      :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          :label="$t('generics.search')"
          class="px-4 primary"
          prepend-icon="mdi-magnify"
          color="secondary"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>

        <v-progress-circular
          v-if="loadingDeleteUser === item._id"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-icon v-else :disabled="!!loadingDeleteUser" @click="deleteItem(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <driver-handler
      v-if="createDriverDialog"
      v-model="createDriverDialog"
      :is-new="true"
    />
    <driver-handler
      v-if="updateDriverDialog"
      v-model="updateDriverDialog"
      :is-new="false"
      :client="chosen"
    />
    <div class="floating-btn">
      <v-btn
        fab
        big
        class="mx-2"
        color="secondary"
        @click="createDriverDialog = true"
      >
        <v-icon color="primary">mdi-plus</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>
mix
<script>
import DriverHandler from '@/components/DriverHandler'

export default {
  name: 'DriversPage',
  components: { DriverHandler },
  data() {
    return {
      loading: false,
      createDriverDialog: false,
      updateDriverDialog: false,
      excelDialog: false,
      chosen: null,
      search: '',
      loadingDeleteUser: false,
      headers: [
        { text: this.$t('userDetails.fullName'), value: 'fullname' },
        { text: this.$t('userDetails.phone'), value: 'phone' },
        { text: this.$t('generics.actions'), value: 'actions' },
      ],
    }
  },
  computed: {
    drivers() {
      return this.$store.getters.drivers
    },
  },
  methods: {
    async editItem(u) {
      this.chosen = u
      this.updateDriverDialog = true
    },
    async deleteItem(item) {
      this.loadingDeleteUser = item._id

      let { isConfirmed } = await this.$createSwal({
        title: this.$t('generics.delete'),
      })
      if (isConfirmed) {
        await this.$store.dispatch('driver/destroy', item._id)
      }
      this.loadingDeleteUser = false
    },
  },
  async mounted() {
    if (!this.drivers.length) {
      await this.$store.dispatch('driver/index')
    }
  },
}
</script>
