import dayjs from 'dayjs'
const bothHave = (objsArr, value, date = false) => {
  if (date) {
    return isSameDay(objsArr[0][value], objsArr[1][value])
  }
  return objsArr[0][value] === objsArr[1][value]
}
const orderBidHandlerMixin = {
  methods: {
    mxIcountNeedToUpdate() {
      const bid = this.recordInDB
      const bidMenu = bid.menu
      const bidProducts = bid.menu?.products
      const { form } = this
      const formMenu = form.menus?.[0]
      const formProducts = formMenu?.products
      if (!bothHave([formMenu, bidMenu], 'discount')) return 1
      if (!bothHave([bidProducts, formProducts], 'length')) return 1
      if (!bothHave([form.client, bid.client], 'fullname')) return 1
      if (!bothHave([form.client, bid.client], 'phone')) return 1
      // if (!bothHave([form, bid], 'paymentMethod')) return 1
      // if (!bothHave([form, bid], 'infoOnPayment')) return 1
      // if (!bothHave([form.delivery, bid.delivery], 'type')) return 1
      if (!bothHave([form.delivery, bid.delivery], 'day', true)) return 1
      // if (!bothHave([form.delivery, bid.delivery], 'startHour')) return 1
      // if (!bothHave([form, bid], 'serveInfo')) return 1
      // if (!bothHave([form, bid], 'info')) return 1
      // if (!bothHave([form, bid], 'additionalInfo')) return 1
      // if (!bothHave([form, bid], 'menuInfo')) return 11
      // if (
      //   bid.delivery.type === 'delivery' &&
      //   !bothHave([form.delivery, bid.delivery], 'address')
      // ) {
      //   return 1
      // }
      // if (
      //   bid.delivery.type === 'delivery' &&
      //   !bothHave([form.delivery, bid.delivery], 'info')
      // ) {
      //   return 1
      // }

      for (let index = 0; index < bidProducts?.length; index++) {
        let bidProduct = bidProducts?.[index]
        let formProduct = formProducts?.[index]
        if (!bothHave([bidProduct, formProduct], 'quantity')) return 1
        if (!bothHave([bidProduct.product, formProduct.product], 'price')) {
          return 1
        }
        if (!bothHave([bidProduct.product, formProduct.product], 'title')) {
          return 1
        }
      }
      return 0
    },
  },
}

function isSameDay(dateA, dateB) {
  return dayjs(dateA).isSame(dayjs(dateB), 'day')
}

export default orderBidHandlerMixin
