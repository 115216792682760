import myAxios from '../../services/myAxios.js'

export default {
  state: {
    client: null, //chosen client
    clients: [],
    filtered: [],
  },
  getters: {
    clients: state =>
      state.clients.sort((a, b) => a.fullname?.localeCompare(b.fullname)),
    client: state => state.client,
    clientsFiltered: state => state.filtered,
  },
  mutations: {
    //sets all clients
    'clients/set': (state, payload) => {
      state.clients = payload
      state.filtered = [...state.clients]
    },
    //sets one client
    'client/set': (state, payload) => (state.client = payload),
    //filters the client's array by client's key and client's val
    'clients/filter': (state, { key, val }) => {
      state.filtered = !val
        ? [...state.clients]
        : state.clients.filter(f => f[key] === val)
    },
    //store one client
    'client/store': (state, payload) => {
      state.clients.push(payload)
      state.client = payload
    },
    //destroys one client
    'client/destroy': (state, id) =>
      (state.clients = state.clients.filter(item => {
        return item._id !== id
      })),
    //updates one client
    'client/update': (state, payload) => {
      state.clients = state.clients.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
  },
  actions: {
    //fetch all clients
    'client/index': async context => {
      const { data } = await myAxios.get('/user/clients')
      context.commit('clients/set', data)
    },
    //fetch one client by id
    'client/show': async (context, id) => {
      let { data } = await myAxios.get('/user/' + id)
      context.commit('client/set', data)
    },
    //stores one client
    'client/store': async (context, payload) => {
      let { data } = await myAxios.post('/user', { ...payload })
      context.commit('client/store', data)
    },
    //destroys one client
    'client/destroy': async (context, id) => {
      await myAxios.delete('/user/client/' + id)
      context.commit('client/destroy', id)
    },
    //updates one client by its id
    'client/update': async (context, payload) => {
      await myAxios.put('/user/client/' + payload._id, payload)
      context.commit('client/update', payload)
    },
    //upload Excel file of users to server
    'client/xl-upload': async (context, payload) => {
      let formData = new FormData()
      formData.append('xl', payload)
      const { data } = await myAxios.post('/user/client/from-xl', formData)
      console.log(data)
    },
    'client/order': async (context, payload) => {
      const { data: orders } = await myAxios.get('/user/order/' + payload._id)
      return orders
    },
  },
}
