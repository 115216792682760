<template>
  <div>
    <filter-app-bar
      v-if="isFilterDefaultValueLoaded"
      @updateStart="updateStart"
      @updateEnd="updateEnd"
      @updateDeliveryDate="updateDeliveryDate"
      :defaultStart="filters.start"
      :defaultEnd="filters.end"
      :defaultIsDeliveryDateValue="filters.deliveryDate"
      :defaultFilterObj="defaultFilter"
    />
    <v-container v-if="isFilterDefaultValueLoaded">
      <h2 class="text-center text-h5">
        {{
          !filters.end
            ? dayBySlash(filters.start)
            : `${dayBySlash(filters.end)} - ${dayBySlash(filters.start)}`
        }}
      </h2>
      <h1 class="text-center mb-5 mt-3">{{ $t('generics.supply') }}</h1>
      <v-card class="pa-3">
        <orders-table @onRowClick="handleClick" :orders="filteredOrders" />
      </v-card>
      <OrderDialog :type="-1" :order="selectedOrder" v-model="showDialog" />
    </v-container>
  </div>
</template>

<script>
import OrdersTable from '../components/OrdersTable.vue'
import OrderDialog from '../components/OrderDialog.vue'
import FilterAppBar from '../layouts/FilterAppBar.vue'
import genericsServices from '../services/generics'
import dayjs from 'dayjs'
import Times from '../helpers/times'
import FiltersCaching from '@/services/FiltersCaching.js'

export default {
  name: 'ShopPage',
  components: { OrdersTable, OrderDialog, FilterAppBar },
  data() {
    return {
      selectedOrder: null,
      showDialog: false,
      filters: {
        start: null,
        end: null,
        deliveryDate: null,
      },
    }
  },
  computed: {
    isFilterDefaultValueLoaded() {
      return (
        this.filters.start &&
        this.filters.end &&
        this.filters.deliveryDate !== null
      )
    },
    orders() {
      return this.$store.getters.orders
    },
    filteredOrders() {
      return genericsServices.filterOrders(
        this.orders,
        this.filters.start,
        this.filters.end,
        this.filters.deliveryDate
      )
    },
    defaultFilter() {
      const today = dayjs()
      return {
        start: today.format(Times.dateFormat),
        end: today.format(Times.dateFormat),
      }
    },
  },
  methods: {
    handleClick(v) {
      this.selectedOrder = v
      this.showDialog = true
    },
    updateModel(v) {
      this.showDialog = v
    },
    updateStart(v) {
      this.filters.start = v
    },
    updateEnd(v) {
      this.filters.end = v
    },
    updateDeliveryDate(v) {
      this.filters.deliveryDate = v
    },
    checkIfDeliveryOrCreatedAt(order) {
      return this.filters.isDeliveryDate ? order.delivery.day : order.createdAt
    },
    dayBySlash(date) {
      return Times.dayBySlash(date)
    },
  },
  async mounted() {
    await this.$store.dispatch('order/index')
    const { start, end, deliveryDate } = FiltersCaching.get({ type: 'all' })
    this.filters.start = start || this.defaultFilter.start
    this.filters.end = end || this.defaultFilter.end
    this.filters.deliveryDate = deliveryDate !== undefined ? deliveryDate : true
  },
  watch: {
    'filters.start'(value) {
      FiltersCaching.save({ type: 'all', key: 'start', value })
    },
    'filters.end'(value) {
      FiltersCaching.save({ type: 'all', key: 'end', value })
    },
    'filters.deliveryDate'(value) {
      FiltersCaching.save({ type: 'all', key: 'deliveryDate', value })
    },
  },
}
</script>
