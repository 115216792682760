<template>
  <div class="customer-details">
    <h1>{{ title }}</h1>
    <div style="text-align: center !important">
      <span
        v-if="fullname"
        style="text-align: start !important"
        :style="`${!phone ? 'margin:auto' : ''}`"
      >
        {{ $t('client.name') }}: {{ fullname }}
      </span>
      <span v-else />
      <span v-if="phone.includes('-')"
        >{{ $t('userManagement.phone') }} {{ phone }}</span
      >
      <span v-else-if="phone">{{ $t('client.userId') }} {{ phone }}</span>
      <span v-else />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderCustomerDetails',
  props: {
    title: { type: String, default: '' },
    pdfData: Object,
  },
  computed: {
    fullname() {
      return this.pdfData.fullname ?? ''
    },
    userId() {
      return this.pdfData.userId ?? ''
    },
    phone() {
      return this.pdfData.phone ?? ''
    },
  },
}
</script>
<style lang="scss" scoped>
.customer-details {
  background-color: var(--secondaryLight);
  margin: 4px 0;
  padding: 10px;
  border-radius: 4px;

  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 1.85rem;
  }

  div {
    display: flex;
    font-size: 1.25rem;
    justify-content: space-around;
  }
}
</style>
