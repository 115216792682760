import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import VueNumericInput from 'vue-numeric-input'
import vuetify from './plugins/vuetify'
import JsonExcel from 'vue-json-excel'
import i18n from './i18n'
import 'izitoast/dist/css/iziToast.min.css'
import Notifications from './plugins/notifications'
import GenericServices from './plugins/generics'
import NProgress from 'vue-nprogress'
import './styles/global.scss'
import './styles/table.scss'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

Vue.config.productionTip = false

Vue.component('downloadExcel', JsonExcel)
Vue.use(Notifications)
Vue.use(GenericServices)
Vue.use(VueNumericInput)
Vue.use(NProgress)

dayjs.extend(customParseFormat)

const nprogress = new NProgress()
const init = async () => {
  //checks user auth status
  try {
    await store.dispatch(`auth/checkLogin`, {
      toastOptions: { disabled: true },
    })
  } catch {
    console.log('No Auth')
  }

  const app = new Vue({
    nprogress,
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
  }).$mount('#app')
  store.$vue = app
}

init()
