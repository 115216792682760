import myAxios from '../../services/myAxios'

export default {
  state: {
    product: null, //chosen product
    products: [],
    filtered: [],
    subCategory: [],
  },
  getters: {
    products: state => state.products,
    product: state => state.product,
    productsFiltered: state => state.filtered,
    productsObject: state => {
      return state.products.reduce((a, c) => {
        if (!c.catalogNumber) return a
        a[c.catalogNumber] = c
        return a
      }, {})
    },
    subCategory: state => state.subCategory,
  },
  mutations: {
    //sets all products
    'products/set': (state, payload) => {
      state.products = payload
      state.filtered = [...state.products]
    },
    //sets one product
    'product/set': (state, payload) => (state.product = payload),
    //filters the product's array by product's key and product's val
    'products/filter': (state, { key, val }) => {
      state.filtered = !val
        ? [...state.products]
        : state.products.filter(f => f[key] === val)
    },
    //store one product
    'product/store': (state, payload) => state.products.push(payload),
    //destroys one product
    'product/destroy': (state, id) =>
      (state.products = state.products.filter(item => {
        return item._id !== id
      })),
    //updates one product
    'product/update': (state, payload) => {
      state.products = state.products.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
    'subCategoryIndex/set': (state, payload) => (state.subCategory = payload),
    'subCategoryIndex/delete': (state, _id) => {
      state.subCategory = state.subCategory.filter(item => {
        return item._id !== _id
      })
    },
  },
  actions: {
    //fetch all products
    'product/index': async context => {
      const { data } = await myAxios.get('/product')
      context.commit('products/set', data)
    },
    //fetch one product by id
    'product/show': async (context, id) => {
      let { data } = await myAxios.get('/product/' + id)
      context.commit('product/set', data)
    },
    //stores one product
    'product/store': async (context, payload) => {
      let formData = new FormData()
      for (let key in payload) {
        if (key == 'department') {
          formData.append(key, JSON.stringify(payload[key]))
        }
        formData.append(key, payload[key])
      }
      let { data } = await myAxios.post('/product', formData)
      context.commit('product/store', data)
    },
    //destroys one product
    'product/destroy': async (context, id) => {
      await myAxios.delete('/product/' + id)
      context.commit('product/destroy', id)
    },
    //updates one product by its id
    'product/update': async (context, payload) => {
      let formData = new FormData()
      for (let key in payload) {
        if (key == 'department') {
          formData.append(key, JSON.stringify(payload[key]))
        }
        formData.append(key, payload[key])
      }
      const { data } = await myAxios.put('/product/' + payload._id, formData)
      context.commit('product/update', data)
    },

    'product/xl-upload': async (context, payload) => {
      let formData = new FormData()
      formData.append('xl', payload)
      const { data } = await myAxios.post('/product/from-xl', formData)
      console.log(data)
    },
    'product/subCategoryIndex': async context => {
      const { data } = await myAxios.get('/product/sub-category')
      context.commit('subCategoryIndex/set', data)
    },
    'product/deleteSubCategory': async (context, { _id }) => {
      await myAxios.delete('/product/sub-category/' + _id)
      context.commit('subCategoryIndex/delete', _id)
    },
  },
}
