<template>
  <div>
    <my-dialog
      :title="form.product.title"
      v-model="openModal"
      :maxWidth="600"
      :persistent="true"
    >
      <template v-slot:content>
        <v-row>
          <v-col>
            <v-combobox
              :items="itemsInAutocomplete"
              v-model="form.product.title"
              :hide-no-data="hideNoData"
              autofocus
              :label="$t('generics.name')"
              @keyup="changeAutocompleteHandler"
              @change="chosenAutocompleteHandler"
            />
          </v-col>
        </v-row>
        <v-row v-if="showPrecDetails">
          <v-col>
            <v-text-field
              :label="$t('generics.notes')"
              v-model="form.product.info"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="showPrecDetails" justify="center">
          <v-col class="center-all">
            <number-picker
              :label="$t('generics.quantity')"
              v-model="form.quantity"
              :min="0"
            />
          </v-col>
        </v-row>
        <v-row v-if="showPrecDetails" justify="center">
          <v-col cols="3" class="center-all">
            <number-picker
              :label="$t('product.price')"
              v-model="form.product.price"
              :min="0"
            />
          </v-col>
        </v-row>

        <v-row v-if="showPrecDetails">
          <v-col class="d-flex justify-center">
            <div>
              <span>{{ $t('menu.sumPrice') }} </span>
              <span>{{ form.product.price * form.quantity }} ₪</span>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="showPrecDetails && !form.product.catalogNumber"
          class="center-all"
        >
          <v-checkbox
            v-model="form.product.box"
            :label="$t('generics.inBox')"
            @change="handleCheckboxChange('box')"
          />
          <v-checkbox
            class="ms-8"
            v-model="form.product.isSingleProduct"
            :label="$t('generics.isSingleProduct')"
            @change="handleCheckboxChange('isSingleProduct')"
          />
        </v-row>
        <v-row v-if="true">
          <v-col class="d-flex justify-center">
            <v-checkbox
              v-model="form.isTitle"
              :label="$t('product.isTitle')"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="err">
          <v-col class="error--text text-center">
            {{ err }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn color="primary" @click="submit" :disabled="!form.product.title">
          {{ $t('generics.save') }}
        </v-btn>
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from './generics/MyDialog.vue'
import _ from 'lodash'
import NumberPicker from './generics/NumberPicker.vue'
import ObjectID from 'bson-objectid'

export default {
  name: 'product-handler',
  components: { MyDialog, NumberPicker },
  props: { value: Boolean, p: Object, isNew: Boolean },
  data: () => ({
    err: null,
    form: {
      _id: ObjectID().toHexString(),
      isTitle: false,
      quantity: 1,
      product: {
        box: false,
        price: 0,
        isSingleProduct: false,
      },
    },
    itemsInAutocomplete: [],
    hideNoData: true,
  }),
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    products() {
      return this.$store.getters.products
    },
    showPrecDetails() {
      return this.form.product.title && !this.form.isTitle
    },
  },
  methods: {
    chosenAutocompleteHandler(userInputPick) {
      if (!userInputPick) {
        this.form.product.info = null
        this.form.product.price = null
        return
      }
      let product = this.$store.getters.products?.find(
        product => product.title === userInputPick
      )
      if (product) {
        delete product._id
        product = _.cloneDeep(product)
        if (!product.info) this.form.product.info = ''
      } else {
        product = {
          title: userInputPick,
          price: 1,
          info: '',
        }
        this.itemsInAutocomplete = []
        this.hideNoData = true
      }
      this.form.product = product
    },
    changeAutocompleteHandler(event) {
      if (event.keyCode === 13) return
      const inputTextLength = event.target.value.length
      const noItemsInAutoComplete = !this.itemsInAutocomplete.length
      if (inputTextLength > 1 && noItemsInAutoComplete) {
        this.hideNoData = false
        this.itemsInAutocomplete = this.$store.getters.products?.map(
          product => product.title
        )
      }
      if (!noItemsInAutoComplete && inputTextLength < 2) {
        this.itemsInAutocomplete = []
        this.hideNoData = true
      }
    },
    clearProductForTitle() {
      this.form = {
        ...this.form,
        quantity: 0,
        product: {
          ...this.form.product,
          price: 0,
        },
      }
    },
    async submit() {
      if (!this.validateForm()) {
        return this.createErr('this form is invalid')
      }
      if (this.form.isTitle) {
        this.clearProductForTitle()
      }
      const action = this.isNew ? 'createProduct' : 'editProduct'
      this.$emit(action, this.form)
      this.openModal = false
    },
    validateForm() {
      return this.form.product
    },
    createErr(msg) {
      this.err = msg
      setTimeout(() => {
        this.err = null
      }, 2000)
    },
    handleCheckboxChange(field) {
      if (this.form.product[field]) {
        this.form.product[field === 'box' ? 'isSingleProduct' : 'box'] = false;
      }
    },
  },
  async mounted() {
    if (!this.isNew) this.form = _.cloneDeep(this.p)
    if (!this.products.length) {
      await this.$store.dispatch('product/index')
    }
  },
  watch: {
    'form.product.title'(v) {
      if (v === 'משלוח') {
        this.form.isPicked = true
        this.form.isReady = true
      }
    }
  },
}
</script>
