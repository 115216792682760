<template>
  <div>
    <filter-app-bar
      v-if="isFilterDefaultValueLoaded"
      @updateDeliveryDate="updateDeliveryDate"
      @updateStart="updateStart"
      @updateEnd="updateEnd"
      :defaultIsDeliveryDateValue="true"
      :showIsDeliveryDateSwitch="false"
      :defaultEnd="filters.end"
      :defaultStart="filters.start"
      :defaultFilterObj="defaultFilter"
      :disabled="!initialDataFetchFinish"
    />
    <v-container v-if="isFilterDefaultValueLoaded" class="container mt-2">
      <h2 class="text-center text-h5">
        {{ !endDate ? startDate : `${endDate} - ${startDate}` }}
      </h2>
      <h1 class="text-center mb-5 mt-3">{{ $t('generics.departments') }}</h1>
      <v-row>
        <v-col cols="1">
          <v-btn @click="loadData" class="primary" icon>
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-select
            :items="titleDepartments"
            item-value="value"
            solo
            v-model="selectedDepartment"
          ></v-select>
        </v-col>
      </v-row>

      <template v-if="thereAreOrdersForToday">
        <v-tabs v-model="tab" background-color="transparent" hide-slider>
          <v-tab href="#0">{{ $t('order.main') }}</v-tab>
          <v-tab href="#1" class="white--text">{{
            $t('generics.hours')
          }}</v-tab>
          <!-- <v-tab href="#2">{{ $t('order.index') }}</v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="0">
            <kitchen-department-by-product
              :filters="filters"
              :department="selectedDepartment"
            />
          </v-tab-item>
          <v-tab-item value="1">
            <kitchen-department-by-hours
              :filters="filters"
              :department="selectedDepartment"
            />
          </v-tab-item>
          <v-tab-item value="2">
            <kitchen-department-orders
              :filters="filters"
              :department="selectedDepartment"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
      <h2 v-else>{{ $t('generics.noOrdersToday') }}</h2>
    </v-container>
  </div>
</template>

<script>
import KitchenDepartmentByProduct from '../components/department-tabs/KitchenDepartmentByProduct.vue'
import KitchenDepartmentByHours from '../components/department-tabs/KitchenDepartmentByHours.vue'
import KitchenDepartmentOrders from '../components/department-tabs/KitchenDepartmentOrders.vue'
import FilterAppBar from '../layouts/FilterAppBar.vue'
import dayjs from 'dayjs'
import Times from '../helpers/times'
import FiltersCaching from '@/services/FiltersCaching.js'

export default {
  name: 'departments-page',
  components: {
    KitchenDepartmentByProduct,
    KitchenDepartmentByHours,
    KitchenDepartmentOrders,
    FilterAppBar,
  },
  data() {
    return {
      tab: null,
      items: [
        this.$t('order.main'),
        this.$t('order.index'),
        this.$t('generics.hours'),
      ],
      departments: [
        {
          value: 'bakery',
          key: 1,
          text: this.$t('departments[1]'),
        },
        {
          value: 'kitchen',
          key: 4,
          text: this.$t('departments[4]'),
        },
        {
          value: 'sandwiches',
          key: 7,
          text: this.$t('departments[7]'),
        },
        {
          value: 'shipping',
          key: 12,
          text: this.$t('departments[12]'),
        },
        {
          value: 'general',
          key: 9,
          text: this.$t('departments[9]'),
        },
      ],
      selectedDepartment: null,
      filters: {
        start: null,
        end: null,
        isDeliveryDate: null,
      },
      startDate: '',
      endDate: '',
      initialDataFetchFinish: false,
      initialDataFetchStart: false,
    }
  },
  computed: {
    isFilterDefaultValueLoaded() {
      return this.filters.start && this.filters.end
    },
    defaultFilter() {
      const today = dayjs()
      return {
        start: today.format(Times.dateFormat),
        end: today.format(Times.dateFormat),
      }
    },
    thereAreOrdersForToday() {
      // return this.$store.getters.todayOrders?.length
      return true //TODO change
    },
    titleDepartments() {
      const departments = this.departments.map(d => {
        d.text = `${this.$t('generics.department2')} ${d.text}`
        d.value = d.key
        return d
      })
      return [
        {
          value: null,
          text: this.$t('generics.allDepartments'),
        },
        ...departments,
      ]
    },
    dateTitle() {
      let start = this.filters?.start || ''
      let end = this.filters?.end || ''
      let date = ''
      if (end === start) {
        date = start
      } else if (!start && end) {
        date = end
      } else if (start && !end) {
        date = start
      } else {
        date = `${start} - ${end}`
      }
      return date
    },
  },
  methods: {
    async loadData() {
      if (this.initialDataFetchStart) return
      this.initialDataFetchStart = true
      await Promise.all([
        this.orderIndex(),
        this.$store.dispatch('product/index'),
      ])
      this.initialDataFetchFinish = true
    },
    async orderIndex() {
      this.$store.dispatch('order/index', {
        min: this.filters.start,
        max: this.filters.end,
      })
    },
    updateStart(v) {
      this.filters.start = v
      this.startDate = v ? dayjs(v).format('DD/MM/YYYY') : ''
    },
    updateEnd(v) {
      this.filters.end = v
      this.endDate = v ? dayjs(v).format('DD/MM/YYYY') : ''
    },
    updateDeliveryDate(v) {
      this.filters.isDeliveryDate = v
    },
  },
  async mounted() {
    const { start, end, selectedDepartment } = FiltersCaching.get({
      type: 'all',
    })
    this.filters.start = start || this.defaultFilter.start
    this.filters.end = end || this.defaultFilter.end
    this.selectedDepartment = selectedDepartment || null
    this.loadData()
  },
  watch: {
    'filters.start'(value) {
      FiltersCaching.save({ type: 'all', key: 'start', value })
      if (!this.initialDataFetchFinish) return
      this.orderIndex()
    },
    'filters.end'(value) {
      FiltersCaching.save({ type: 'all', key: 'end', value })
      if (!this.initialDataFetchFinish) return
      this.orderIndex()
    },
    selectedDepartment(value) {
      FiltersCaching.save({
        type: 'all',
        key: 'selectedDepartment',
        value,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/global.scss';

.container::v-deep {
  .v-tabs-slider-wrapper {
    height: 5px !important;
  }

  .v-tab {
    border-radius: 1rem 1rem 0 0;
    margin: 0 2px;
    transition: 0.2s;

    &:nth-of-type(1) {
      background-color: #72634b;
    }

    &:nth-of-type(2) {
      background-color: rgb(184, 147, 74);
    }

    &:nth-of-type(3) {
      background-color: #fb7a7a;
    }

    &.v-tab--active {
      font-weight: 700;
      border-radius: 0.5rem 0.5rem 0 0;
      width: 9em;
      border-bottom: 5px solid;
    }
  }

  .v-tabs-items {
    border-radius: 0 0 2rem 2rem;
  }
}
</style>
