<template>
  <v-row dense class="primary px-3" justify="center" align="center">
    <v-btn
      v-if="bin"
      :input-value="binModel"
      @click="$emit('update:binModel')"
      class="mb-7 me-4"
      icon
    >
      <v-icon>mdi-recycle</v-icon>
    </v-btn>
    <div v-if="showSwitch" class="align-self-start d-flex align-center me-5">
      <span>{{
        $t(`generics.${isBidsPage ? 'filterBarAllBids' : 'filterBarAll'}`)
      }}</span>
      <v-switch v-model="showDateFilter" inset color="secondary"></v-switch
      ><span class="ms-n1">{{ $t('generics.filterBarDates') }}</span>
    </div>
    <v-col cols="6" sm="4" class="align-center">
      <my-date-picker
        :disabled="disabled || disableFilterUI"
        :label="$t('generics.fromDate')"
        v-model="startComputed"
      />
    </v-col>
    <v-col cols="6" sm="4">
      <my-date-picker
        :label="$t('generics.untilDate')"
        v-model="endComputed"
        :start="start"
        :disabled="disabled || disableFilterUI"
      />
    </v-col>
    <!-- <v-col v-if="showIsDeliveryDateSwitch" cols="8" sm="auto">
      <v-switch
        color="secondary"
        v-model="isDeliveryDate"
        :label="`${
          isDeliveryDate ? $t('date.supplyDate') : $t('date.productionDate')
        }`"
      />
    </v-col> -->
    <v-col cols="8" sm="auto">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-on="on"
            v-bind="attrs"
            @click="defaultHandler"
            class="mt-n8"
            :disabled="disableFilterUI"
          >
            <v-icon>mdi-calendar-refresh-outline</v-icon></v-btn
          >
        </template>
        <span>{{ $t('generics.backToCalendarDefault') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      cols="4"
      sm="1"
      class="d-flex justify-end mt-n7"
      v-if="showBtn && $store.getters.role === 0"
    >
      <my-btn @click="handleClick" />
    </v-col>
  </v-row>
</template>
<script>
import MyDatePicker from '@/components/generics/MyDatePicker.vue'
import MyBtn from '@/components/generics/MyBtn.vue'
import dayjs from 'dayjs'
import Times from '../helpers/times'
import Notifications from '@/services/notifications'

export default {
  name: 'filter-app-bar',
  data() {
    return {
      start: null,
      end: null,
      isProductionDate: false,
      isDeliveryDate: false,
    }
  },
  computed: {
    showDateFilter: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    startComputed: {
      get() {
        return this.start
      },
      set(v) {
        if (dayjs(v).isAfter(this.end)) {
          v = this.end
          Notifications.errorToast('', 'בחרת תאריך לא תקין')
        }
        this.start = v
      },
    },
    endComputed: {
      get() {
        return this.end
      },
      set(v) {
        if (dayjs(v).isBefore(this.start)) v = this.start
        this.end = v
      },
    },
    disableFilterUI() {
      if (!this.showSwitch) return false
      if (!this.showDateFilter) return true
      return false
    },
  },
  props: {
    binModel: Boolean,
    bin: { type: Boolean, default: false },
    showBtn: { type: Boolean, default: false },
    defaultIsDeliveryDateValue: { type: Boolean, default: true },
    showIsDeliveryDateSwitch: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    defaultEnd: String,
    defaultStart: String,
    defaultFilterObj: Object,
    showSwitch: Boolean,
    value: Boolean,
    isBidsPage: { Boolean, default: false },
  },
  watch: {
    start(v) {
      if (this.end) {
        const end = dayjs(this.end, Times.dateFormat).unix()
        const start = dayjs(v, Times.dateFormat).unix()

        //if the end date is the before the start date
        if (end < start) {
          this.end = null
        }
      }

      this.$emit('updateStart', v)
    },
    end(v) {
      this.$emit('updateEnd', v)
    },
    isProductionDate(v) {
      this.isProductionDate(v)
    },
    isDeliveryDate(v) {
      this.$emit('updateDeliveryDate', v)
    },
  },
  methods: {
    defaultHandler() {
      const { start, end } = this.defaultFilterObj
      this.end = end
      this.start = start
    },
    handleClick() {
      this.$emit('handleClick')
    },
    updateIsProduction(v) {
      this.$emit('updateIsProduction', v)
    },
    updateIsDelivery(v) {
      this.$emit('updateIsDelivery', v)
    },
  },
  components: { MyDatePicker, MyBtn },
  async mounted() {
    this.isDeliveryDate = this.defaultIsDeliveryDateValue
    this.start = this.defaultStart
    await this.$nextTick()
    this.end = this.defaultEnd
  },
}
</script>
