<template>
  <v-btn
    @click="$emit('click')"
    :disabled="disabled"
    rounded
    :small="isSmall"
    :large="!isSmall"
    dark
    color="secondary"
    v-bind="$attrs"
  >
    <v-icon dark class="primary--text"> mdi-plus </v-icon>
  </v-btn>
</template>
<script>
export default {
  name: 'my-btn',
  props: {
    isSmall: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
}
</script>
