<template>
  <my-dialog
    :title="`${$t('order.storeSuccess')}!`"
    v-model="openModal"
    :persistent="false"
    :titleCenter="true"
  >
    <template v-slot:content>
      <v-container>
        <!-- <v-row>
          <v-col>
            <div class="text-end">{{ $t('order.one') }}</div>
            <div class="d-flex align-center">
              <v-btn
                :disabled="allowCopy"
                @click="copyLink(pdfUrl)"
                color="primary py-6"
                x-large
              >
                {{ $t('generics.copyLink') }}
              </v-btn>
              <v-text-field
                disabled
                :placeholder="pdfUrl"
                outlined
                hide-details
                dir="ltr"
              />
            </div>
          </v-col>
        </v-row> -->
        <v-row v-if="showPaymentLink">
          <v-col>
            <div>{{ $t('generics.copyPayLink') }}</div>
            <div class="d-flex align-center justify-center">
              <v-text-field
                disabled
                :placeholder="order.linkToPayment"
                outlined
                hide-details
                dir="rtl"
              />
              <v-btn
                :disabled="!allowCopy"
                @click="copyLink(order.linkToPayment)"
                color="primary py-6"
              >
                {{ $t('generics.copyLink') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <div class="center-all mt-5">
          <v-btn class="primary px-1" @click="openPdf"
            ><v-icon>mdi-download</v-icon></v-btn
          >
        </div>
      </v-container>
    </template>
    <!-- <template v-slot:actions>
      <div class="pb-6">
        <v-btn
          :disabled="!allowEmail"
          class="mx-9"
          color="primary"
          x-large
          @click="sendEmail"
          >שלח בדוא"ל
        </v-btn>
        <v-btn
          :disabled="true"
          class="mx-9"
          color="primary"
          x-large
          @click="sendSms"
          >שלח בSMS
        </v-btn>
        <v-btn
          class="mx-9"
          color="primary"
          x-large
          link
          :href="pdfUrl"
          target="_blank"
          >הורדת קובץ הזמנה
        </v-btn>
      </div>
    </template> -->
  </my-dialog>
</template>

<script>
import MyDialog from '@/components/generics/MyDialog.vue'

export default {
  name: 'order-payment-data-dialog',
  components: {
    MyDialog,
  },
  props: {
    value: Boolean,
    order: Object,
    showPaymentLink: { type: Boolean, default: true },
    isBid: { type: Boolean, default: false },
    menuId: String,
    redirectPath: String,
    allowCopy: { type: Boolean, default: true },
    allowEmail: { type: Boolean, default: true },
  },
  data() {
    return {
      orderLink: '',
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        if (!val && this.redirectPath) {
          this.$router.push(this.redirectPath)
        }
      },
    },
    pdfUrl() {
      return `${document.location.origin}/orders/pdf/${this.order._id}`
    },
  },
  methods: {
    openPdf() {
      window.open(this.pdfUrl, '_blank')
    },
    sendEmail() {
      this.$store.dispatch('order/sendPaymentLink', { orderId: this.order._id })
    },
    sendSms() {
      // TODO: add dispatch method for send sms
      return
    },
    copyLink(link) {
      navigator.clipboard.writeText(link)
    },
  },
  watch: {
    openModal(newV, oldV) {
      if (newV && !oldV) {
        this.openPdf()
      }
      if (!newV && !!oldV) {
        this.$router.push('/orders')
      }
    },
  },
}
</script>
