<template>
  <v-navigation-drawer
    app
    right
    v-model="openModal"
    color="secondary"
    class="custom-drawer"
  >
    <v-row justify="center" class="mt-5">
      <v-col cols="6">
        <v-img src="/logo.png" />
      </v-col>
    </v-row>

    <v-list
      nav
      dense
      v-for="(item, i) in items.filter((_, i) => i < 6)"
      :key="i"
      max-height="40"
      class="px-0"
    >
      <v-list-item
        @click="goRoute(item.route)"
        :disabled="$route.name === item.route"
      >
        <v-list-item-title
          :class="`font-weight-bold text-center ${
            isActive(item.route) ? 'primary--text' : 'white--text'
          }`"
          v-text="item.title"
          style="font-size: 15px"
        />
      </v-list-item>
    </v-list>
    <v-divider class="primary mt-4 mb-1" />
    <v-list
      nav
      dense
      v-for="(item, i) in items.filter((_, i) => i > 5)"
      :key="i + 10"
      max-height="40"
      class="px-0"
    >
      <v-list-item
        @click="goRoute(item.route)"
        :disabled="$route.name === item.route"
      >
        <v-list-item-title
          :class="`font-weight-bold text-center ${
            isActive(item.route) ? 'primary--text' : 'white--text'
          }`"
          v-text="item.title"
          style="font-size: 15px"
        />
      </v-list-item>
    </v-list>
    <v-divider class="primary mt-3" />
    <v-list-item @click="logout">
      <v-list-item-title
        icon="mdi-logout"
        class="white--text text-center ms-n4 mt-8"
      >
        <v-icon class="primary--text">mdi-logout</v-icon>
        {{ $t('auth.logout') }}
      </v-list-item-title>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'Drawer',
  props: { value: Boolean },
  data() {
    return {
      excelDialog: false,
    }
  },
  computed: {
    items() {
      const buttonsData = [
        {
          title: this.$tc('bid.index', 2),
          icon: 'mdi-home',
          route: 'Bids',
          roles: [0, 7],
        },
        {
          title: this.$t('order.index'),
          icon: 'mdi-home',
          route: 'Orders',
          roles: [0, 7],
        },
        {
          title: this.$t('generics.departments'),
          icon: 'mdi-home',
          route: 'Departments',
          roles: [0, 1, 7],
        },
        {
          title: this.$t('collection.index'),
          icon: 'mdi-home',
          route: 'Collection',
          roles: [0, 2, 7],
        },
        // {
        //   title: this.$t('generics.supply'),
        //   icon: 'mdi-home',
        //   route: 'Shop',
        //   roles: [0, 3, 7],
        // },
        {
          title: this.$t('generics.deliveries'),
          icon: 'mdi-account-group-outlinet',
          route: 'Shipments',
          roles: [6, 0, 7],
        },
        {
          title: this.$t('generics.ordersNotPaidFromWeb'),
          route: 'ordersNotPaidFromWeb',
          oneAdmin: true,
        },
        {
          title: this.$t('product.titlePlural'),
          icon: 'mdi-cart-variant',
          route: 'Products',
          roles: [0, 7],
        },
        {
          title: this.$t('client.handlerTitleclients'),
          icon: 'mdi-account-group-outlinet',
          route: 'Users',
          roles: [0, 7],
        },
        {
          title: this.$t('client.handlerTitleDrivers'),
          icon: 'mdi-account-group-outlinet',
          route: 'Drivers',
          roles: [0, 7],
        },
      ]

      // filter unauthorized routes
      return buttonsData.filter(r => {
        if (r.oneAdmin) {
          if (this.$store.getters.email == 'mirush1981@nougatine.co.il') return true
          else return false
        }
        if (!r.roles) return true
        return r.roles.includes(this.$store.getters.role)
      })
    },

    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    isActive(routeName) {
      return this.$route.name === routeName
    },
    goRoute(r) {
      this.$router.push({ name: r })
      this.openModal = false
    },
    async logout() {
      this.$store.dispatch('auth/logout', {
        toastOptions: { disabled: true },
      })
    },
  },
}
</script>
<style scoped>
.custom-drawer {
  background-image: url('/background.png');
  background-size: cover;
  background-position: center;
}
</style>
