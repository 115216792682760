export const afterDiscount = (total, discount) => {
  return total - findDiscount(total, discount)
}

export const findDiscount = (total, discount) => {
  return (total * discount) / 100
}

const roundedAmount = total => {
  let result = String(total).split('.')

  return result[1] ? result[1] : 0
}

export const calculateRounded = (amount, discount) => {
  // Total discount
  // let sumDiscount = findDiscount(amount,discount).toFixed()

  // Total after discount
  let total = afterDiscount(amount, discount)
  // Total rounding after discount
  let rounded = roundedAmount(total.toFixed(2))
  total = total.toFixed()

  // Check if rounding is for client
  let isRoundedForClient = rounded < 50

  // Discount to string
  if (isRoundedForClient) {
    rounded = `0.${rounded} -`
  } else {
    rounded = (1 - Number(`0.${rounded}`)).toFixed(2)
  }

  return { total, rounded }
}
