<template>
  <section>
    <v-card-title class="d-flex align-center">
      <v-row>
        <v-col cols="10" sm="11">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            icon
            :label="$t('generics.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2" sm="1">
          <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="orders"
      :search="search"
      :loading="loading"
      :items-per-page="20"
      @click:row="onClickRow"
      class="app-table"
      :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
      sort-by="delivery"
    >
      <!-- <template v-slot:item.delivery="{ item }">
        <span v-if="item.delivery.type === 'self'">איסןף עצמי</span>
        <div v-else class="text-center">
          <div>{{ item.delivery.address }}</div>
          <div>{{ item.delivery.info }}</div>
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">

                <v-icon  v-on="on"
                  v-bind="attrs"  class="red--text" v-if="!item.delivery.deliveryMan">mdi-motorbike</v-icon>
              </template>
              <span>לא תואם שליח</span>
            </v-tooltip>

        </div>
      </template> -->
      <template v-slot:item.shipping="{ item }">
        <span>{{ item.shipping }}</span>
      </template>
      <template v-slot:item.delivery="{ item }">
        <span style="white-space: nowrap">{{
          item.delivery.adjustedStartHour
        }}</span>
      </template>
      <template v-slot:item.clientName="{ item }">
        <h3>{{ item.client && item.client.fullname }}</h3>
      </template>
      <template v-slot:item.menu="{ item }">
        <v-row>
          <v-col cols="10">
            <span>{{
              item.menu.products
                .map(
                  ({ product: { title }, quantity }) => `${quantity} ${title}`
                )
                .join(', ')
            }}</span>
          </v-col>
          <v-col cols="2">
            <v-icon v-if="checkThereAreNotes(item)">mdi-information</v-icon>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <order-description
      :order="chosen"
      v-model="orderDescriptionDialogOpen"
      :type="-1"
    />
  </section>
</template>

<script>
import OrderDescription from '../OrderDialog.vue'
import dayjs from 'dayjs'
export default {
  name: 'kitchen-department-orders',
  props: {
    filters: { type: Object },
    department: { type: String },
  },
  components: { OrderDescription },
  data() {
    return {
      loading: false,
      search: '',
      headers: [
        {
          text: this.$t('generics.collectTime'),
          value: 'delivery',
          sort: (a, b) => {
            return (
              this.parseToFullDelivaryNumberDate(a) -
              this.parseToFullDelivaryNumberDate(b)
            )
          },
        },
        {
          text: this.$t('delivery.index'),
          value: 'shipping',
          cellClass: 'col-1',
        },
        { text: this.$t('order.num'), value: 'orderNum' },
        { text: this.$tc('client.index'), value: 'client.fullname' },
        {
          text: this.$t('generics.orderDetails'),
          value: 'menu',
          sortable: false,
        },
      ],
      chosen: null,
      orderDescriptionDialogOpen: false,
    }
  },
  computed: {
    orders() {
      const { start, end, isDeliveryDate } = this.filters
      return this.$store.getters.ordersFilterByDepartment({
        start,
        end,
        isDeliveryDate,
        department: this.department,
      })
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('order/index')
    },
    checkThereAreNotes(order) {
      return order.info || order?.menu.products.some(({ info }) => info)
    },
    onClickRow(order) {
      this.chosen = order
      this.orderDescriptionDialogOpen = true
    },
    parseToFullDelivaryNumberDate(deliveryObj) {
      const { startHour } = deliveryObj
      const time = startHour.includes(' - ')
        ? startHour.split('-')[1]
        : startHour
      const hour = Number(time.split(':')[0])
      const minutes = Number(time.split(':')[1])
      return dayjs(deliveryObj.day)
        .set('hour', hour)
        .set('minute', minutes)
        .valueOf()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.app-table::v-deep tbody tr {
  cursor: pointer !important;

  &:hover {
    background: rgba($color: $primary, $alpha: 0.3) !important;
  }
}
</style>
