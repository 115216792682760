<template>
  <v-container>
    <h3 class="text-center mt-10">{{ $t('product.titlePlural') }}</h3>
    <v-data-table
      :headers="headers"
      :items="products"
      :loading="loading"
      :search="search"
      :items-per-page="20"
      id="orders-table"
      class="app-table"
      :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          :label="$t('generics.search')"
          class="px-4 primary"
          prepend-icon="mdi-magnify"
          color="secondary"
        />
      </template>
      <template v-slot:item.image="{ item }">
        <v-img
          :src="`/uploads/${item.image}`"
          max-width="100"
          max-height="100"
        />
      </template>
      <template v-slot:item.box="{ item }">
        <v-icon color="green" v-if="item.box">mdi-check</v-icon>
      </template>
      <template v-slot:item.isSingleProduct="{ item }">
        <v-icon color="green" v-if="item.isSingleProduct">mdi-check</v-icon>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <product-handler
      v-if="createProductDialog"
      v-model="createProductDialog"
      :is-new="true"
    />
    <product-handler
      v-if="updateProductDialog"
      v-model="updateProductDialog"
      :is-new="false"
      :product="chosen"
    />
    <div class="floating-btn">
      <v-btn fab big class="mx-2" color="green" @click="excelDialog = true">
        <v-icon color="white">mdi-microsoft-excel</v-icon>
      </v-btn>
      <v-btn
        fab
        big
        class="mx-2"
        color="secondary"
        @click="createProductDialog = true"
      >
        <v-icon color="primary">mdi-plus</v-icon>
      </v-btn>
    </div>
    <drag-file
      :handle-upload="handleExcel"
      v-model="excelDialog"
      :title="$t('generics.uploadExcel')"
    />
  </v-container>
</template>

<script>
import ProductHandler from '@/components/ProductHandler'
import DragFile from '@/components/generics/DragFile'

export default {
  name: 'ProductsPage',
  components: { ProductHandler, DragFile },
  data() {
    return {
      loading: false,
      createProductDialog: false,
      updateProductDialog: false,
      excelDialog: false,
      chosen: null,
      search: '',
      headers: [
        { text: this.$t('generics.product'), value: 'title', width: '25%' },
        { text: this.$t('generics.price'), value: 'price' },
        { text: this.$t('generics.department'), value: 'department' },
        { text: this.$t('generics.category'), value: 'category' },
        { text: this.$t('generics.subCategory'), value: 'subCategory.text' },
        { text: this.$t('generics.moreDetails'), value: 'info' },
        { text: this.$t('generics.image'), value: 'image' },
        { text: this.$t('generics.catalogNumberText'), value: 'catalogNumber' },
        { text: this.$t('generics.inBox'), value: 'box' },
        { text: this.$t('generics.isSingleProduct'), value: 'isSingleProduct' },
        { text: this.$t('generics.actions'), value: 'actions' },
      ],
    }
  },
  computed: {
    products() {
      return this.$store.getters.products.map(p => ({
        ...p,
        department: this.$t(`departments.${p.department['key']}`),
      }))
    },
  },
  methods: {
    async editItem(p) {
      this.chosen = this.$store.getters.products.find(
        item => p._id === item._id
      )
      this.updateProductDialog = true
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.$createSwal({
        title: this.$t('generics.delete'),
      })
      if (isConfirmed) {
        await this.$store.dispatch('product/destroy', item._id)
      }
    },
    async handleExcel(file) {
      await this.$store.dispatch('product/xl-upload', file)
      await this.$store.dispatch('product/index')
      this.excelDialog = false
    },
  },
  async mounted() {
    if (!this.products.length) {
      await this.$store.dispatch('product/index')
      await this.$store.dispatch('product/subCategoryIndex')
    }
  },
}
</script>
