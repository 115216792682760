<template>
  <div>
    <my-dialog
      :title="productData.title"
      v-model="openModal"
      :persistent="false"
    >
      <template v-slot:content v-if="productData">
        <v-data-table
          :headers="headers"
          :items="productData.infoNotes"
          :items-per-page="50"
          :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
          class="app-table"
          :item-class="itemRowBackground"
          @click:row="editOrder"
        >
          <template v-slot:item.day="{ item }">
            <span style="white-space: nowrap">
              {{ dayjs(item.day).format('DD-MM-YYYY') }}</span
            ></template
          >

          <template v-slot:item.serveInfo="{ item: { serveInfo } }">
            <span class="font-weight-bold" v-if="serveInfo">{{
              $t(`delivery.servingTypes.${serveInfo}`)
            }}</span>
          </template>
          <template v-slot:item.status="{ item, index }">
            <v-checkbox
              class="big-checkbox"
              color="primary"
              v-model="item.isReady"
              @change="onChangeStatus(item, index)"
            />
          </template>
        </v-data-table>
      </template>
    </my-dialog>
    <order-dialog
      v-if="openDialog"
      v-model="openDialog"
      :orderID="chosenOrder"
      :type="-1"
    />
  </div>
</template>

<script>
import OrderDialog from '@/components/OrderDialog.vue'
import MyDialog from '@/components/generics/MyDialog.vue'
import dayjs from 'dayjs'

export default {
  name: 'product-info-dialog',
  components: {
    MyDialog,
    OrderDialog,
  },
  props: { value: Boolean, productData: Object },
  data() {
    return {
      openDialog: false,
      chosenOrder: null,
      headers: [
        {
          text: this.$t('order.num'),
          value: 'orderNum',
        },
        { text: this.$t('generics.date'), value: 'day', width: '10px' },
        {
          text: this.$t('generics.hour'),
          value: 'deliveryTime',
          width: '10px',
          cellClass: 'nowrap',
        },
        { text: this.$tc('client.index'), value: 'clientName' },
        { text: this.$t('generics.quantity'), value: 'quantity' },
        {
          text: this.$t('delivery.servingType'),
          value: 'serveInfo',
          cellClass: 'nowrap',
        },
        { text: this.$t('generics.info'), value: 'info' },
        {
          text: this.$t('generics.notesOnMenuForTable'),
          value: 'menuInfo',
          width: '10%',
        },
        {
          text: this.$t('generics.internalNotesForTable'),
          value: 'internalNotes',
          width: '10%',
        },
        {
          text: '',
          value: 'status',
        },
      ],
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    async onChangeStatus(item, index) {
      const { isReady } = item
      const order = this.productData?.ids[index]
      const orderId = order?.orderId
      const productId = order?.productStatusId
      await this.$store.dispatch('order/productStatus', {
        orderId,
        productId,
        isReady,
      })
    },
    editOrder({ orderNum }, _, e) {
      if (e.target.className.includes('v-input')) return
      this.chosenOrder = this.$store.getters.orders.find(
        order => order.orderNum === orderNum
      )?._id
      this.openDialog = true
    },
    dayjs,
    itemRowBackground(item) {
      if (item.isReady) return 'success'
    },
  },
}
</script>

<style scoped>
.smallHeader {
  width: 10px !important;
  min-width: 10px !important;
}
</style>
