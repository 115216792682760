import dayjs from 'dayjs'
export default class FiltersCaching {
  /**
   * Saves a filter value to local storage (under specific type- bids , orders etc).
   * @param {string} type - the page type to save the filter.
   * @param {string} key - The key to updates its value.
   * @param {any} value - The new value associated with key.
   */
  static save({ type, key, value }) {
    let currentCaching = window.localStorage.getItem('currentCaching')
    try {
      if (currentCaching) {
        currentCaching = JSON.parse(currentCaching)
      } else {
        currentCaching = {}
      }
    } catch (e) {}

    const newKeyValue = {}
    newKeyValue[key] = value
    const currentCachingType = currentCaching[type] || {}
    const newCurrentCachingByType = {
      ...currentCachingType,
      ...newKeyValue,
    }
    const newCurrentCaching = {
      ...currentCaching,
      [type]: newCurrentCachingByType,
      date: dayjs().toString(),
    }
    try {
      window.localStorage.setItem(
        'currentCaching',
        JSON.stringify(newCurrentCaching)
      )
    } catch (e) {}
  }

  /**
   * Returns the current local storage caching by type.
   * @param {string} type - the page type to its filter caching data.
   */
  static get({ type }) {
    try {
      let currentCaching = window.localStorage.getItem('currentCaching')
      if (!currentCaching) return {}
      const parse = JSON.parse(currentCaching)
      if (
        !parse.date ||
        dayjs()
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .isAfter(dayjs(parse.date))
      ) {
        window.localStorage.removeItem('currentCaching')
        return {}
      }
      return parse?.[type] || {}
    } catch (e) {
      return {}
    }
  }
}
