const genericMixins = {
  methods: {
    emailValidation(email) {
      let regEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
      return regEx.test(email)
    },
    extractClapErrorHe(e) {
      if (e && e.response && e.response.data && e.response.data.clapErr) {
        let errObj = e.response.data
        return errObj.he
      } else {
        return 'משהו השתבש'
      }
    },
    extractClapErrorEn(e) {
      if (e && e.response && e.response.data && e.response.data.clapErr) {
        let errObj = e.response.data
        return errObj.err
      } else {
        return 'Oops! Something went wrong'
      }
    },
  },
}

export default genericMixins
