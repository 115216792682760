<template>
  <v-app-bar flat color="primary">
    <v-row class="justify-space-between px-5">
      <v-app-bar-nav-icon color="secondary" @click="openDrawer" />
      <v-toolbar-title class="seconady--text">{{
        $t('appTitle')
      }}</v-toolbar-title>
      <v-btn text @click="goBack">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      drawer: false,
    }
  },
  methods: {
    openDrawer() {
      this.$emit('toggleDrawer')
    },
    canGoBack() {
      if (
        window &&
        window.history &&
        window.history.length &&
        window.history.length > 2
      )
        return true
      return false
    },
    goBack() {
      if (this.canGoBack) {
        this.$router.back()
      }
    },
  },
}
</script>
