var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","icon":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"loading":_vm.loading,"search":_vm.search,"items-per-page":20,"id":"orders-table","item-class":_vm.itemRowBackground,"sort-by":"delivery","footer-props":{ 'items-per-page-options': [100, 30, 50, -1] }},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.client.fullname",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.client.fullname)+" "),(_vm.isWoocommerce(item))?_c('v-icon',[_vm._v("mdi-web")]):_vm._e()],1)]}},{key:"item.delivery",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-dlex column"},[_c('div',[_vm._v(_vm._s(_vm.getOrderDay(item.delivery)))]),_c('div',[_vm._v(_vm._s(_vm.getOrderDayString(item.delivery)))]),_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.delivery.adjustedStartHour)+" ")])])]}},{key:"item.delivery.address",fn:function(ref){
var item = ref.item;
return [(item.delivery.type === 'delivery')?_c('div',[_vm._v(" "+_vm._s(item.delivery.address)+" ")]):_vm._e()]}},{key:"item.box",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numOfBoxes(item))+" ")]}},{key:"item.isSingleProduct",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numOfSingleProducts(item))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('span',[_vm._v(_vm._s(_vm.$t(("order." + (item.status)))))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteOrder(item)}}},[_c('v-icon',{attrs:{"color":"red","size":"24"}},[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.restoreOrder(item)}}},[_c('v-icon',{attrs:{"color":"green","size":"24"}},[_vm._v(" mdi-delete-restore ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }