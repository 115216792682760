<template>
  <v-dialog max-width="900">
    <v-img :src="src" />
    <template v-slot:activator="{ on, attrs }">
      <v-img
        :src="src"
        max-width="100"
        max-height="100"
        contain
        v-bind="attrs"
        v-on="on"
      />
    </template>
  </v-dialog>
</template>
<script>
export default {
  name: 'image-show',
  props: {
    src: String,
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
