import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
// import timezone from "dayjs/plugin/timezone";
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/he'

dayjs.extend(utc)
// dayjs.extend(timezone);
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.updateLocale('he', {
  relativeTime: {
    future: 'בעוד %s',
    past: 'לפני %s',
    s: 'כמה רגעים',
    m: 'דקה',
    mm: '%d דקות',
    h: 'שעה',
    hh: '%d שעות',
    d: 'יום',
    dd: '%d ימים',
    M: 'חודש',
    MM: '%d חודשים',
    y: 'שנה',
    yy: '%d שנים',
  },
})

const dayStringMap = {
  0: { he: 'ראשון' },
  1: { he: 'שני' },
  2: { he: 'שלישי' },
  3: { he: 'רביעי' },
  4: { he: 'חמישי' },
  5: { he: 'שישי' },
  6: { he: 'שבת' },
}

const dayLetterMap = {
  0: { he: 'א' },
  1: { he: 'ב' },
  2: { he: 'ג' },
  3: { he: 'ד' },
  4: { he: 'ה' },
  5: { he: 'ו' },
  6: { he: 'ז' },
}

export default class Times {
  static dateFormat = 'YYYY-MM-DD'
  static dateFormatRTL = 'DD-MM-YYYY'

  static dateByDot(date) {
    return date ? dayjs(date).format('DD.MM.YYYY') : ''
  }
  static dayBySlash(date) {
    return date ? dayjs(date).format('DD/MM/YYYY') : ''
  }
  static formatTime(time) {
    return dayjs(time).format('DD.MM.YY HH:mmA')
  }
  static extractDateString(date = new Date(), isRtl = false) {
    //keeping just in case needed again in future
    const format = isRtl ? Times.dateFormat : Times.dateFormatRTL
    // const format = Times.dateFormatRTL
    return date ? dayjs(date).format(format) : ''
  }
  static extractTimeString(date) {
    return date ? dayjs(date).format('HH:mm') : ''
  }
  static extractDayString(date) {
    if (!date) return ''
    let dayCode = dayjs(date).day()
    return dayStringMap[dayCode].he
  }
  static setDateTime(date, time) {
    date = dayjs(date)
    let dateStr = this.extractDateString(date)
    dateStr += `T${time}`
    return dayjs(dateStr)
  }
  static isSameDay(d1, d2) {
    return this.extractDateString(d1) === this.extractDateString(d2)
  }
  static clacRelativeTimeString(date) {
    return dayjs(date).locale(this.$i18n.locale).fromNow()
  }
  static sortByISODate(dateISO1, dateISO2) {
    const date1 = new Date(dateISO1)
    const date2 = new Date(dateISO2)
    return date1 > date2 ? -1 : date1 < date2 ? 1 : 0
  }
  //checking if x's day is between y1, y2 days, or same as one
  static isSameOrBetweenDays(x, y1, y2) {
    x = dayjs(x)
    y1 = dayjs(y1)
    y2 = dayjs(y2)
    return x.isBetween(y1, y2) || this.isSameDay(x, y1) || this.isSameDay(x, y2)
  }
  //checking if day y1 is before y2
  static isBeforeDays(y1, y2) {
    return dayjs(y1).isBefore(dayjs(y2))
  }
  //checking x's range overlaps with y's range
  static overLaps(xStart, xEnd, yStart, yEnd) {
    xStart = dayjs(xStart)
    xEnd = dayjs(xEnd)
    yStart = dayjs(yStart)
    yEnd = dayjs(yEnd)
    return (
      xStart.isBetween(yStart, yEnd) ||
      (xStart.isSameOrBefore(yStart) && (!xEnd || xEnd.isSameOrAfter(yEnd)))
    )
  }

  static timeFormatValidator(time) {
    return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time)
  }
  static setDay(date, day) {
    return dayjs(date).add(day, 'day')
  }

  static substractTimeFromTime(time, hours = 0, mins = 0) {
    if (!Times.timeFormatValidator(time)) return time
    let timeArr = time.split(':')
    let h = Number(timeArr[0])
    let m = Number(timeArr[1])
    if (typeof h === NaN || typeof m === NaN) return time
    h -= hours
    if (m - mins < 0) {
      m -= mins
      m %= 60
      m += 60
      h -= 1
    } else m -= mins
    if (h === 0 && mins === 0) return time
    h = h < 10 ? `0${h}` : `${h}`
    m = m < 10 ? `0${m}` : `${m}`
    let result = `${h}:${m}`
    return result
  }

  /**
   * This function in given a start time and an end time (both in HH:mm, examples: 15:30, 04:03, 09:09) and a jump in minutes
   * returns an array with all the ranges between.
   * @param {String} startRaw The start time for the range
   * @param {String} endRaw The end time for the range
   * @param {Number} jump The jump between each time in minutes
   * @returns An array with all the time ranges in that range
   * For Example:
   * Given a start of 07:00, an end of 09:00 and a jump of 30, the fuction will return
   * ["07:00 - 07:30", "07:30 - 08:00", "08:30", "08:30 - 09:00"]
   */
  static getRangesInRange(startRaw, endRaw, jump) {
    const format = 'HH:mm'
    const start = dayjs(startRaw, format)
    const end = dayjs(endRaw, format)
    const out = []
    let currentTime = start
    while (currentTime.add(jump, 'minute').isSameOrBefore(end)) {
      let entry = currentTime.format(format)
      entry = `${currentTime.add(jump, 'minute').format(format)} - ${entry}`
      currentTime = currentTime.add(60, 'minute')
      out.push(entry)
    }
    return out
  }

  /**
   * This function in given a start time and an end time (both in HH:mm, examples: 15:30, 04:03, 09:09) and a jump in minutes
   * returns an array with all the times between.
   * @param {String} startRaw The start time for the range
   * @param {String} endRaw The end time for the range
   * @param {Number} jump The jump between each time in minutes
   * @returns An array with all the times in that range
   * For Example:
   * Given a start of 07:00, an end of 09:00 and a jump of 30, the fuction will return
   * ["07:00","07:30","08:00","08:30","09:30"]
   */
  static getTimesInRange(startRaw, endRaw, jump) {
    const format = 'HH:mm'
    const start = dayjs(startRaw, format)
    const end = dayjs(endRaw, format)
    const out = []

    let currentTime = start
    out.push(currentTime.format(format))
    while (currentTime.isBefore(end)) {
      currentTime = currentTime.add(jump, 'minute')
      out.push(currentTime.format(format))
    }

    out.push(currentTime.format(format))
    return out
  }
  static timesFromStringComparison = (a, b) => {
    // Combines time string parsing and comparison into a single function
    const parseTimeString = delivery => {
      // If startHourTime exists, return it directly
      if (delivery.pickUpTime) {
        const [hours, minutes] = delivery.pickUpTime.split(':')
        return Number(hours) + Number(minutes) / 60
      }

      // Otherwise parse the startHour string
      const timeStr = delivery.startHour
      const timeParts = timeStr.split(' - ')
      const timeValue = timeParts[1] ? timeParts[1] : timeParts[0]
      const [hours, minutes] = timeValue.split(':')
      return Number(hours) + Number(minutes) / 60
    }

    const aTime = parseTimeString(a.delivery)
    const bTime = parseTimeString(b.delivery)

    if (aTime > bTime) return 1
    if (aTime < bTime) return -1
    return 0
  }
  static formatedDay(dateString) {
    const dayDate = dayjs(dateString)
    return `${dayDate.format('DD/MM/YYYY')} יום ${
      dayLetterMap[dayDate.format('d')]['he']
    }'`
  }
}
