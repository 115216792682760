var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h3',{staticClass:"text-center mt-10"},[_vm._v(_vm._s(_vm.$t('product.titlePlural')))]),_c('v-data-table',{staticClass:"app-table",attrs:{"headers":_vm.headers,"items":_vm.products,"loading":_vm.loading,"search":_vm.search,"items-per-page":20,"id":"orders-table","footer-props":{ 'items-per-page-options': [100, 30, 50, -1] }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"px-4 primary",attrs:{"label":_vm.$t('generics.search'),"prepend-icon":"mdi-magnify","color":"secondary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":("/uploads/" + (item.image)),"max-width":"100","max-height":"100"}})]}},{key:"item.box",fn:function(ref){
var item = ref.item;
return [(item.box)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.isSingleProduct",fn:function(ref){
var item = ref.item;
return [(item.isSingleProduct)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}])}),(_vm.createProductDialog)?_c('product-handler',{attrs:{"is-new":true},model:{value:(_vm.createProductDialog),callback:function ($$v) {_vm.createProductDialog=$$v},expression:"createProductDialog"}}):_vm._e(),(_vm.updateProductDialog)?_c('product-handler',{attrs:{"is-new":false,"product":_vm.chosen},model:{value:(_vm.updateProductDialog),callback:function ($$v) {_vm.updateProductDialog=$$v},expression:"updateProductDialog"}}):_vm._e(),_c('div',{staticClass:"floating-btn"},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","big":"","color":"green"},on:{"click":function($event){_vm.excelDialog = true}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-microsoft-excel")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","big":"","color":"secondary"},on:{"click":function($event){_vm.createProductDialog = true}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)],1),_c('drag-file',{attrs:{"handle-upload":_vm.handleExcel,"title":_vm.$t('generics.uploadExcel')},model:{value:(_vm.excelDialog),callback:function ($$v) {_vm.excelDialog=$$v},expression:"excelDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }