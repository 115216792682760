import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store/index'
import Login from '../views/Login.vue'
import BidsPage from '../views/BidsPage.vue'
import BidHandler from '../components/OrderBidHandler.vue'
import OrdersPage from '../views/OrderPage.vue'
import DepartmentsPage from '../views/DepartmentsPage.vue'
import BidApproval from '../views/BidApproval.vue'
import NotFound from '../views/NotFound.vue'
import ProductsPage from '@/views/ProductsPage'
import UsersPage from '@/views/ClientsPage'
import DriversPage from '@/views/DriversPage'
import OrderPdf from '@/views/OrderPdf'
import CollectionPage from '@/views/CollectionPage'
import Shop from '@/views/Shop.vue'
import ShipmentsPage from '@/views/ShipmentsPage.vue'

Vue.use(VueRouter)

const authGuard = async (to, from, next) => {
  const isAuth = store.getters.isAuth
  if (!isAuth) return next({ name: 'Login' })

  const userRole = store.getters.role
  const routeRoles = to.meta?.roles
  if (!routeRoles || routeRoles.includes(userRole)) return next()

  return next({ name: 'NotFound' })
}
const beforeEnterLoginPage = async (to, from, next) => {
  const isAuth = store.getters.isAuth
  const initialPage = store.getters.role === 6 ? 'Shipments' : 'Departments'
  return isAuth ? next({ name: initialPage }) : next()
}
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: beforeEnterLoginPage,
  },
  {
    path: '/bids',
    name: 'Bids',
    component: BidsPage,
    meta: { roles: [0, 7] },
    beforeEnter: authGuard,
  },
  {
    path: '/bid/handler',
    name: 'BidHandler',
    component: BidHandler,
    props: true,
    meta: { roles: [0, 7] },
    beforeEnter: authGuard,
  },
  {
    path: '/bid/approval',
    name: 'BidApproval',
    component: BidApproval,
    props: true,
    meta: { roles: [0, 7] },
    beforeEnter: authGuard,
  },
  {
    path: '/orders',
    name: 'Orders',
    component: OrdersPage,
    meta: { roles: [0, 7] },
    beforeEnter: authGuard,
  },
  {
    path: '/collection',
    name: 'Collection',
    component: CollectionPage,
    meta: { roles: [0, 7] },
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
    meta: { roles: [0, 3, 7] },
    beforeEnter: authGuard,
  },
  {
    path: '/departments',
    name: 'Departments',
    component: DepartmentsPage,
    meta: { roles: [0, 1, 7] },
    beforeEnter: authGuard,
  },
  {
    path: '/products',
    name: 'Products',
    component: ProductsPage,
    meta: { roles: [0, 7] },
    beforeEnter: authGuard,
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersPage,
    meta: { roles: [0, 7] },
    beforeEnter: authGuard,
  },
  {
    path: '/drivers',
    name: 'Drivers',
    component: DriversPage,
    meta: { roles: [0, 7] },
    beforeEnter: authGuard,
  },
  {
    path: '/shipments',
    name: 'Shipments',
    component: ShipmentsPage,
    meta: { roles: [6, 0, 7] },
    beforeEnter: authGuard,
  },
  {
    path: '/orders/pdf/:id',
    name: 'OrdersPdf',
    component: OrderPdf,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    beforeEnter: authGuard,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
