import myAxios from '../../services/myAxios.js'

export default {
  actions: {
    'email/send': async (context, payload) => {
      try {
        await myAxios.post('/email', payload)
        return true
      } catch (e) {
        return false
      }
    },
  },
}
