<template>
  <section>
    <v-card-title class="d-flex align-center">
      <v-row>
        <v-col cols="10" sm="11">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            icon
            :label="$t('generics.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2" sm="1">
          <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="productsByType"
      :loading="loading"
      :items-per-page="20"
      class="app-table"
      :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
      @click:row="openInfo"
    >
      <template v-slot:item.image="{ item }">
        <image-show :src="`/uploads/${item.image}`" />
      </template>
      <template v-slot:item.product="{ item }">
        <h3>{{ item.title }}</h3>
      </template>
      <template v-slot:item.totalQuantity="{ item }">
        <h3>{{ item.totalQuantity }}</h3>
      </template>

      <template v-slot:item.infoNotes="{ item }">
        <v-row align="center" class="secondary--text">
          <v-col cols="12">
            <div
              v-if="
                item.infoNotes.filter(
                  infoNote =>
                    infoNote.info || infoNote.internalNotes || infoNote.menuInfo
                ).length
              "
              class="link red--text"
            >
              <h3>
                {{
                  $t('generics.numOfNotes', {
                    num: countInfoNotes(item.infoNotes),
                  })
                }}
              </h3>
            </div>
            <h4 v-else class="grey--text text--darken-2">
              {{ $t('generics.noNotes') }}
            </h4>
          </v-col>
          <!-- <v-col cols="6" class="d-flex"> -->
          <!-- <v-img src="@/assets/meal.png" max-width="3rem" contain class="ml-3" /> -->
          <!-- <v-tooltip bottom v-if="item.paidQuantity != item.totalQuantity">
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  src="@/assets/credit-card.png"
                  max-width="3rem"
                  class="mouse-pointer"
                  contain
                  v-on="on"
                  v-bind="attrs"
                />
              </template>
              <span>{{
                $t('order.paidForFrom', {
                  paidQuantity: item.paidQuantity,
                  totalQuantity: item.totalQuantity,
                })
              }}</span>
            </v-tooltip>
          </v-col> -->
        </v-row>
      </template>
      <template v-slot:item.status="{ item }">
        <v-checkbox
          class="big-checkbox ms-n4"
          color="success"
          v-model="item.isReady"
          @change="onChangeStatus(item)"
        />
      </template>
    </v-data-table>
    <product-info-dialog
      v-if="openInfoDialog"
      v-model="openInfoDialog"
      :productData="chosen"
    />
  </section>
</template>

<script>
import ProductInfoDialog from '@/components/dialogs/ProductInfoDialog.vue'
import genericsServices from '../../services/generics'
import ImageShow from '../dialogs/ImageShow.vue'

export default {
  name: 'kitchen-department-by-product',
  components: {
    ProductInfoDialog,
    ImageShow,
  },
  props: {
    department: { type: String },
    filters: { type: Object },
  },
  data() {
    return {
      loading: false,
      search: '',
      headers: [
        { text: this.$t('generics.image'), value: 'image' },
        { text: this.$tc('generics.item'), value: 'product' },
        { text: this.$t('generics.quantity'), value: 'totalQuantity' },
        { text: this.$t('generics.notes'), value: 'infoNotes' },
        {
          text: this.$t('generics.status'),
          value: 'status',
          cellClass: 'status-cell',
          class: 'center-all',
        },
      ],
      openInfoDialog: false,
      chosen: null,
    }
  },
  computed: {
    productsByType() {
      return this.$store.getters.productsByType({
        start: this.filters.start,
        end: this.filters.end,
        isDeliveryDate: this.filters.isDeliveryDate,
        department: this.department,
        search: this.search,
      })
    },
  },
  methods: {
    countInfoNotes(infoNotes) {
      return infoNotes.reduce((acc, curr) => {
        if (curr.info) acc += 1
        if (curr.internalNotes) acc += 1
        if (curr.menuInfo) acc += 1
        return acc
      }, 0)
    },
    async onChangeStatus(item) {
      const { isReady, ids } = item
      const productIds = ids.map(id => id.productStatusId)
      await this.$store.dispatch('order/productStatuses', {
        productIds,
        isReady,
      })
    },
    async loadData() {
      await this.$store.dispatch('order/index')
    },
    openInfo(item, _, e) {
      if (e.target.className.includes('v-input')) return
      this.chosen = item
      this.openInfoDialog = true
    },
  },
}
</script>
<style scoped>
.status-cell {
  border-bottom: none !important;
  height: unset !important;
  display: grid;
  place-items: center;
}
</style>
