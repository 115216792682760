import dayjs from 'dayjs'

class genericsServices {
  static getCookie(cname) {
    let name = cname + '='
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  static filterOrders(orders, start, end, isDeliveryDate) {
    const result = []
    if (start && end) {
      orders.forEach(b => {
        let curDay = dayjs(
          genericsServices.checkIfDeliveryOrCreatedAt(b, isDeliveryDate)
        )
        if (
          curDay.isSameOrAfter(start, 'day') &&
          curDay.isSameOrBefore(end, 'day')
        ) {
          result.push(b)
        }
      })
      return result
    } else if (start) {
      orders.forEach(b => {
        let curDay = dayjs(
          genericsServices.checkIfDeliveryOrCreatedAt(b, isDeliveryDate)
        )
        if (curDay.isSameOrAfter(start, 'day')) {
          result.push(b)
        }
      })
      return result
    } else if (end) {
      orders.forEach(b => {
        let curDay = dayjs(
          genericsServices.checkIfDeliveryOrCreatedAt(b, isDeliveryDate)
        )
        if (curDay.isSameOrBefore(end, 'day')) {
          result.push(b)
        }
      })
      return result
    } else {
      return orders
    }
  }

  static checkIfDeliveryOrCreatedAt(bid, isDeliveryDate) {
    return isDeliveryDate ? bid.delivery.day : bid.createdAt
  }

  /**
    This method merges two arrays togther and keeps some of the order.
    For example if arr1 is [1,2,3]; and arr2 is [4,5,6] the result will be [1,4,2,5,3,6].
    @param {Array} arr1 is the first array,
    @param {Array} arr2 is the second array.
    @returns {Array} the result.
  */
  static mergeArraysTogther(arr1, arr2) {
    let out = []

    arr1.forEach((item, index) => {
      out.push(item)
      if (arr2.length - 1 >= index) {
        out.push(arr2[index])
      }
    })

    if (arr1.length < arr2.length) {
      arr2.splice(0, arr1.length)
      out = [...out, arr2]
    }

    return out
  }
}

export default genericsServices
