<template>
  <v-app>
    <drawer v-if="showDrawer" v-model="drawer" />
    <v-main class="secondaryLight">
      <navbar v-if="showDrawer" @toggleDrawer="toggleDrawer" />
      <router-view />
    </v-main>
    <close-app-footer v-if="showFooter" />
  </v-app>
</template>

<script>
import { isRtlLang } from './i18n'
import Navbar from '@/layouts/Navbar'
import CloseAppFooter from '@/layouts/CloseAppFooter'
import Drawer from '@/layouts/Drawer'

export default {
  name: 'App',
  components: {
    Navbar,
    CloseAppFooter,
    Drawer,
  },
  data() {
    return {
      drawer: false,
    }
  },
  computed: {
    snackbar() {
      return this.$store.getters.snackbar
    },
    showFooter() {
      return true
    },
    showDrawer() {
      const hideRoutes = ['Login', 'OrdersPdf']
      return !hideRoutes.includes(this.$route.name)
    },
  },

  methods: {
    toggleDrawer() {
      console.log(this.drawer)
      this.drawer = !this.drawer
    },
  },
  watch: {
    '$i18n.locale': function (newLocale) {
      const isRtl = isRtlLang(newLocale)
      this.$vuetify.rtl = isRtl
    },
  },
}
</script>
