<template>
  <div>
    <my-dialog
      :title="$t('client.handlerTitle')"
      v-model="openModal"
      :maxWidth="400"
      :persistent="false"
    >
      <template v-slot:content>
        <v-form>
          <v-row>
            <v-text-field
              :label="$t('userDetails.fullName')"
              v-model="form.fullname"
              autofocus
              required
            />
          </v-row>
          <v-row>
            <v-text-field :label="$t('generics.mobile')" v-model="form.phone" />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('userDetails.email')"
              v-model="form.email"
            />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('client.company')"
              v-model="form.company"
            />
          </v-row>
          <!-- <v-row>
            <v-text-field :label="$t('client.userId')" v-model="form.userId" />
          </v-row> -->
          <v-row>
            <v-text-field
              :label="$t('client.city')"
              v-model="form.address.city"
            />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('client.street')"
              v-model="form.address.street"
            />
          </v-row>
          <v-row>
            <v-text-field
              :label="$t('client.houseNum')"
              v-model="form.address.houseNumber"
            />
          </v-row>
          <v-text-field
            :label="$t('client.postalCode')"
            v-model="form.address.postalCode"
          />
          <v-text-field
            :label="$t('delivery.contact')"
            v-model="form.address.contact"
          />
          <v-text-field
            :label="$t('client.icount_id')"
            v-model="form.icount_id"
            type="number"
          />
          <v-row></v-row>
          <!-- <v-row>
            <v-text-field label="ת.ז" v-model="form.socialId" />
          </v-row> -->
          <v-row>
            <v-select
              :items="defaultPaymentMethodItems"
              :label="$t('client.defaultPaymentMethod')"
              v-model="form.defaultPaymentMethod"
            />
          </v-row>
          <v-row v-if="err">
            <v-col class="error--text text-center">
              {{ err }}
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn
          class="black--text#860q31h3t"
          color="primary"
          @click="submit"
          :disabled="!validateForm()"
          >{{ $t('generics.save') }}</v-btn
        >
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from './generics/MyDialog.vue'
import _ from 'lodash'

export default {
  name: 'client-handler',
  components: { MyDialog },
  props: { value: Boolean, client: Object, isNew: Boolean },
  data() {
    return {
      err: null,
      defaultPaymentMethodItems: [
        { text: this.$t('generics.deferred'), value: 'deferred' },
        { text: this.$t('generics.cash'), value: 'cash' },
      ],
      form: {
        fullname: '',
        address: { city: '', street: '', houseNumber: '', postalCode: '' },
        phone: null,
        defaultPaymentMethod: 'deferred',
      },
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    async submit() {
      if (!this.isNew) {
        await this.$store.dispatch('client/update', this.form)
      } else {
        await this.$store.dispatch('client/store', this.form)
      }
      this.openModal = false
    },
    validateForm() {
      return this.form.fullname
    },
    createErr(msg) {
      this.err = msg
      setTimeout(() => {
        this.err = null
      }, 2000)
    },
  },

  mounted() {
    if (!this.isNew) this.form = _.cloneDeep(this.client)
    if (!this.form.address)
      this.form.address = {
        city: '',
        street: '',
        houseNumber: '',
        postalCode: '',
      }
  },
}
</script>
