import myAxios from '../../services/myAxios.js'

export default {
  state: {
    drivers: [],
  },
  getters: {
    drivers: state =>
      state.drivers.sort((a, b) => a.fullname?.localeCompare(b.fullname)),
  },
  mutations: {
    //sets all drivers
    'driver/set': (state, payload) => {
      state.drivers = payload
    },
    //store one drivers
    'driver/store': (state, payload) => {
      state.drivers.push(payload)
    },
    //destroys one drivers
    'driver/destroy': (state, id) =>
      (state.drivers = state.drivers.filter(item => {
        return item._id !== id
      })),
    //updates one driver
    'driver/update': (state, payload) => {
      state.drivers = state.drivers.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
  },
  actions: {
    //fetch all drivers
    'driver/index': async context => {
      const { data } = await myAxios.get('/driver')
      context.commit('driver/set', data)
    },
    //stores one driver
    'driver/store': async (context, payload) => {
      let { data } = await myAxios.post('/driver', { ...payload })
      context.commit('driver/store', data)
    },
    //destroys one driver
    'driver/destroy': async (context, id) => {
      await myAxios.delete('/driver/' + id)
      context.commit('driver/destroy', id)
    },
    //updates one driver by its id
    'driver/update': async (context, payload) => {
      await myAxios.put('/driver/' + payload._id, payload)
      context.commit('driver/update', payload)
    },
  },
}
