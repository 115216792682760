<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="orders"
      :loading="loading"
      :search="search"
      :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
      id="orders-table"
      :item-class="itemRowBackground"
      sort-by="delivery"
      @click:row="editOrder"
    >
      <template v-slot:item.delivery="{ item }">
        <div class="d-flex flex-column">
          <div>{{ getOrderDay(item.delivery) }}</div>
          <div>{{ getOrderDayString(item.delivery) }}</div>
          <div style="white-space: nowrap">
            {{ item.delivery.adjustedStartHour }}
          </div>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status">{{
          $t(`collection.${checkIsPicked(item) ? 'collected' : 'waiting'}`)
        }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <collection-dialog
      v-if="openDialog"
      v-model="openDialog"
      :orderID="chosenOrder"
      :type="-1"
    />
  </div>
</template>
<script>
import CollectionDialog from '@/components/OrderDialog.vue'
import Times from '../helpers/times'
import dayjs from 'dayjs'
export default {
  name: 'collection-table',
  components: { CollectionDialog },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: this.$t('collection.delivery'),
          value: 'delivery',
          class: 'primary lighten-1',
          sort: (a, b) => {
            return (
              this.parseToFullDelivaryNumberDate(a) -
              this.parseToFullDelivaryNumberDate(b)
            )
          },
        },
        {
          text: this.$t('collection.info'),
          value: 'info',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('order.num'),
          value: 'orderNum',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('client.name'),
          value: 'client.fullname',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('generics.mobile'),
          value: 'client.phone',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('collection.address'),
          value: 'delivery.address',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('order.items'),
          value: 'delivery.info',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('generics.status'),
          value: 'status',
          class: 'primary lighten-1',
        },
      ],
      chosenOrder: null,
      openDialog: null,
    }
  },
  props: {
    orders: { type: Array, required: true },
    search: String,
  },
  methods: {
    getOrderDay(delivery) {
      return Times.extractDateString(delivery?.day)
    },
    getOrderDayString(delivery) {
      return Times.extractDayString(delivery?.day)
    },
    itemRowBackground(item) {
      if (this.checkIsPicked(item)) return 'success'
      return 'error'
    },
    checkIsPicked(order) {
      return order.menu.products.every(p => p.isPicked === true)
    },
    editOrder(order) {
      this.chosenOrder = order._id
      this.openDialog = true
    },
    parseToFullDelivaryNumberDate(deliveryObj) {
      const { startHour } = deliveryObj
      const time = startHour.includes(' - ')
        ? startHour.split('-')[1]
        : startHour
      const hour = Number(time.split(':')[0])
      const minutes = Number(time.split(':')[1])
      return dayjs(deliveryObj.day)
        .set('hour', hour)
        .set('minute', minutes)
        .valueOf()
    },
  },
}
</script>
<style scoped lang="scss">
#orders-table {
  background-color: rgba(241, 234, 234, 0.877);
}
</style>
