<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="orders"
      :loading="loading"
      :search="search"
      :items-per-page="20"
      sort-by="delivery"
      id="orders-table"
      :item-class="itemRowBackground"
      :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
      @click:row="onRowClick"
    >
      <template v-slot:item.delivery.pickUpTime="{ item }">
        <v-select
          :items="timeRange"
          class="mt-4"
          outlined
          @click="e => e.stopPropagation()"
          @change="updatePickUpTime(item)"
          v-model="item.delivery.pickUpTime"
        />
      </template>
      <template v-slot:item.delivery="{ item }">
        <div class="d-dlex column">
          <div>{{ getOrderDay(item.delivery) }}</div>
          <div>{{ getOrderDayString(item.delivery) }}</div>
          <div style="white-space: nowrap">
            {{ item.delivery.adjustedStartHour }}
          </div>
        </div>
      </template>
      <template v-slot:item.box="{ item }">
        {{ numOfBoxes(item) }}
      </template>
      <template v-slot:item.isSingleProduct="{ item }">
        {{ numOfSingleProducts(item) }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-icon v-if="item.status === 'delivered'">mdi-check</v-icon>
        <v-icon v-else>mdi-minus</v-icon>
      </template>
    </v-data-table>
    <order-handler
      v-if="orderHandlerDialog"
      v-model="orderHandlerDialog"
      :orderID="chosenOrder"
      :type="-1"
    />
  </div>
</template>
<script>
import Times from '../helpers/times'
import dayjs from 'dayjs'
import OrderHandler from '@/components/OrderDialog.vue'
export default {
  name: 'shipments-table',
  components: { OrderHandler },
  data() {
    return {
      headers: [
        {
          text: this.$t('collection.delivery'),
          value: 'delivery',
          class: 'primary lighten-1',
          sort: (a, b) => {
            return (
              this.parseToFullDelivaryNumberDate(a) -
              this.parseToFullDelivaryNumberDate(b)
            )
          },
        },
        {
          text: this.$t('order.pickUpTime'),
          value: 'delivery.pickUpTime',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('client.name'),
          value: 'client.fullname',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('generics.mobile'),
          value: 'client.phone',
          class: 'primary lighten-1',
          cellClass: 'nowrap',
        },
        {
          text: this.$t('collection.address'),
          value: 'delivery.address',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('order.additionalInfo'),
          value: 'additionalInfo',
          class: 'primary lighten-1 ',
          cellClass: 'max-w-100',
        },
        {
          text: this.$t('generics.notes'),
          value: 'delivery.info',
          class: 'primary lighten-1 max-w-100',
          cellClass: 'max-w-100',
        },
        {
          text: this.$t('generics.internalNotesForTable'),
          value: 'internalNotesShipping',
          class: 'primary lighten-1 max-w-100',
          cellClass: 'max-w-100',
        },
        {
          text: this.$t('generics.boxNum'),
          value: 'box',
          class: 'primary lighten-1 max-w-100',
          cellClass: 'max-w-100',
        },
        {
          text: this.$t('generics.singleProductsNum'),
          value: 'isSingleProduct',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('generics.sent'),
          value: 'status',
          class: 'primary lighten-1',
        },
      ],
      orderHandlerDialog: false,
      chosenOrder: null,
    }
  },
  props: {
    orders: { type: Array, required: true },
    search: String,
    loading: Boolean,
  },
  methods: {
    async updatePickUpTime(order) {
      await this.$store.dispatch('order/update', order)
    },
    numOfBoxes(order) {
      const products = order.menu.products
      return products.reduce((acc, curr) => {
        if (curr?.product?.box) return acc + curr?.quantity
        return acc
      }, 0)
    },
    numOfSingleProducts(order) {
      const products = order.menu.products
      return products.reduce((acc, curr) => {
        if (curr?.product?.isSingleProduct) return acc + curr?.quantity
        return acc
      }, 0)
    },
    onRowClick(order) {
      this.chosenOrder = order._id
      this.orderHandlerDialog = true
    },
    getOrderDay(delivery) {
      return Times.extractDateString(delivery?.day)
    },
    getOrderDayString(delivery) {
      return Times.extractDayString(delivery?.day)
    },
    itemRowBackground(item) {
      if (item.signature || item.delivery.receiver) return 'success'
      else if (item.delivery.deliveryManPickupTime) return 'yellow'
      return 'white'
    },
    parseToFullDelivaryNumberDate(deliveryObj) {
      const { startHour } = deliveryObj
      const time = startHour.includes(' - ')
        ? startHour.split('-')[1]
        : startHour
      const hour = Number(time.split(':')[0])
      const minutes = Number(time.split(':')[1])
      return dayjs(deliveryObj.day)
        .set('hour', hour)
        .set('minute', minutes)
        .valueOf()
    },
  },
  computed: {
    timeRange() {
      return Times.getTimesInRange('05:30', '19:30', 15)
    },
  },
}
</script>
<style scoped lang="scss">
#orders-table {
  background-color: rgba(241, 234, 234, 0.877);
}
</style>
