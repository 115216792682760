import myAxios from '../../services/myAxios'

export default {
  state: {
    bid: null, //chosen bid
    bids: [],
    filtered: [],
  },
  getters: {
    bids: state => state.bids,
    bid: state => state.bid,
    bidsFiltered: state => state.filtered,
  },
  mutations: {
    //sets all bids
    'bids/set': (state, payload) => {
      state.bids = payload
      state.filtered = [...state.bids]
    },
    //sets one bid
    'bid/set': (state, payload) => (state.bid = payload),
    //filters the bid's array by bid's key and bid's val
    'bids/filter': (state, { key, val }) => {
      state.filtered = !val
        ? [...state.bids]
        : state.bids.filter(f => f[key] === val)
    },
    'bids/filterByDate': (state, { start, end }) => {
      //TODO: Binyamin do something here
    },
    //store one bid
    'bid/store': (state, payload) => state.bids.push(payload),
    //destroys one bid
    'bid/destroy': (state, payload) => {
      state.bids = state.bids.filter(item => {
        return item._id !== payload.id
      })
    },
    //updates one bid
    'bid/update': (state, payload) => {
      state.bids = state.bids.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
  },
  actions: {
    //fetch all bids
    'bid/index': async (context, payload) => {
      let url = '/bid'
      if (payload?.bin) {
        url += '?bin=true'
      }
      const response = await myAxios.get(url)
      context.commit('bids/set', response.data)
    },
    //fetch one bid by id
    'bid/show': async (context, payload) => {
      let { data } = await myAxios.get('/bid/' + payload.id)
      context.commit('bid/set', data)
    },
    //stores one bid
    'bid/store': async (context, payload) => {
      let { data } = await myAxios.post('/bid', { ...payload })
      context.commit('bid/store', data)
      return data
    },
    //destroys one bid
    'bid/destroy': async (context, payload) => {
      await myAxios.delete('/bid/' + payload.id)
      context.commit('bid/destroy', payload)
    },
    'bid/restore': async (context, payload) => {
      await myAxios.put('/bid/' + payload.id, { payload, isDeleted: false })
      context.commit('bid/destroy', payload)
    },
    //updates one bid by its id
    'bid/update': async (context, payload) => {
      await myAxios.put('/bid/' + payload._id, payload)
      context.commit('bid/update', payload)
    },
  },
}
