<template>
  <div class="home">
    <v-container class="form-container d-flex justify-center flex-column mt-12">
      <div class="progress" v-if="loading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
          v-if="loading"
        />
      </div>
      <v-row v-else justify="center" align="center" style="height: 80vh">
        <v-col md="8" sm="12">
          <v-card>
            <form @keyup.enter="submit" class="login">
              <v-row justify="center">
                <v-col md="2" cols="4">
                  <v-img class="mt-6" src="/logo2.png" />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col md="8" cols="10">
                  <v-text-field
                    v-model.trim="email"
                    :label="$t('auth.email')"
                    :rules="[rules.emailRequired, rules.validEmail]"
                    required
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col md="8" cols="10">
                  <v-text-field
                    v-model.trim="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="$t('auth.password')"
                    :type="show ? 'text' : 'password'"
                    :rules="[rules.passRequired, rules.min]"
                    @click:append="show = !show"
                    minLength="6"
                    required
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col class="d-flex justify-center">
                  <v-btn
                    @click="submit"
                    text
                    color="primary"
                    :disabled="isValidForm"
                    v-text="$t('auth.login')"
                  />
                </v-col>
              </v-row>

              <v-row class="justify-center"></v-row>
            </form>
          </v-card>
        </v-col>
      </v-row>
      <v-alert
        v-if="isAlertMessage"
        outlined
        type="error"
        class="d-flex justify-center mt-5"
      >
        {{ error }}
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import GenericMixins from '../mixins/generic'

export default {
  name: 'Login',
  mixins: [GenericMixins],
  data: () => {
    return {
      show: false,
      email: '',
      password: '',
      isAlertMessage: false,
      loading: false,
    }
  },
  computed: {
    isValidForm() {
      return !(
        this.email &&
        this.emailValidation(this.email) &&
        this.password &&
        this.password.length >= 6
      )
    },
    rules() {
      return {
        nameRequired: v => !!v || this.$t('auth.fullNameMsg'),
        emailRequired: v => !!v || this.$t('auth.emailRequired'),
        validEmail: v =>
          this.emailValidation(v) || this.$t('auth.emailRequired'),
        passRequired: v => !!v || this.$t('auth.badPassword'),
        min: v => v.length >= 6 || this.$t('auth.shortPassword'),
      }
    },
  },
  methods: {
    async submit() {
      this.loading = true
      try {
        await this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password,
          toastOptions: { disabled: true },
        })
        this.goRoute()
      } catch (e) {
        console.log('No auth')
      } finally {
        this.loading = false
      }
    },
    goRoute() {
      const role = this.$store.getters.role
      switch (role) {
        case 0:
          this.$router.push({ name: 'Departments' })
          break
        case 1:
          this.$router.push({ name: 'Departments' })
          break
        case 2:
          this.$router.push({ name: 'DeliveryCollection' })
          break
        case 3:
          this.$router.push({ name: 'Shop' })
          break
        case 6:
          this.$router.push({ name: 'Shipments' })
          break
        default:
          this.$router.push({ name: 'Departments' })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.home {
  .progress {
    margin: 20% auto;
  }

  .login {
    margin: 10% 0;
  }
}
</style>
