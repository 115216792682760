<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="3">
          <v-card flat>
            <div
              class="primary small-text d-flex justify-center align-center"
              style="height: 40px"
              v-text="'לקוח'"
            />
            <v-card-text class="ma-3">
              <p class="font-weight-black">
                {{ $t(`userTypes.${form.client.role}`) }}
              </p>
              <div class="d-flex flex-column">
                <span>{{ form.client.fullname }}</span>
                <span>{{ form.client.email }}</span>
                <span>{{ form.client.phone }}</span>
              </div>
              <br />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card flat>
            <div
              class="primary small-text d-flex justify-center align-center"
              style="height: 40px"
              v-text="'הצעת מחיר'"
            />
            <v-card-text class="ma-3">
              <v-row>
                <v-col col="4" class="d-flex flex-column">
                  <span class="font-weight-black">זמן אספקה</span>
                  <span>{{ orderDay }} {{ orderDate }}</span>
                  <span>שעת אספקה {{ orderTime }}</span>
                  <span>{{
                    $t(`delivery.servingTypes.${form.serveInfo}`)
                  }}</span>
                </v-col>
                <v-col col="4">
                  <span class="font-weight-black">כתובת אספקה</span>
                  <p>{{ form.delivery.address }}</p>
                </v-col>
                <v-col col="4">
                  <span class="font-weight-black">{{
                    $t('order.additionalInfo')
                  }}</span>
                  <p>{{ form.additionalInfo }}</p>
                </v-col>
              </v-row>
              <v-row justify="center" class="my-2">
                {{ form.delivery.info }}
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-divider />
      </v-row>
      <v-divider class="my-5" />
      <div>
        <h3 class="center-all" v-if="form.menus.length !== 1">אנא בחר הזמנה</h3>
        <v-sheet
          class="mx-auto mt-3 secondaryLight d-flex justify-center"
          max-width="100%"
        >
          <v-slide-group
            show-arrows
            v-model="chosenMenu"
            class="d-flex justify-space-between"
            active-class="active"
            center-active
          >
            <v-slide-item
              v-for="(m, idx) in form.menus"
              :key="m.key"
              class="mx-1"
              v-slot="{ active, toggle }"
            >
              <div :class="active ? 'active' : undefined" @click="toggle">
                <menu-card
                  :canDelete="false"
                  :canEdit="false"
                  :canSave="false"
                  :idx="idx"
                  :menu="m"
                />
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </div>
      <div class="d-flex flex-column justify-center align-center mt-3">
        <div class="secondary--text font-weight-black">
          {{ $t('order.generalNotesMenu') }}
        </div>
        <div>{{ form.menuInfo }}</div>
      </div>
      <v-row justify="center" class="mt-3">
        <v-col>
          <v-select
            :items="payTypes"
            solo
            outlined
            v-model="form.payment"
            item-text="val"
            item-value="key"
            label="צורת תשלום"
          />
        </v-col>
        <v-col>
          <div
            class="primary py-1 px-4 d-flex flex-column"
            style="border-radius: 5px"
          >
            <span class="font-weight-black">הערות:</span>
            <span>{{ form.info }}</span>
          </div>
        </v-col>
        <v-col>
          <div
            class="white py-1 px-4 d-flex flex-column align-center"
            style="border-radius: 5px"
          >
            <span>שליחת הזמנה ללקוח</span>
            <div class="d-flex justify-center">
              <v-icon>mdi-email</v-icon>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center mt-4">
        <v-btn
          :disabled="!validated() || loadingCreateOrder"
          color="secondary"
          class="primary--text"
          @click="createOrder"
        >
          יצירת הזמנה
        </v-btn>
      </div>
    </v-container>
    <order-contact-dialog
      v-if="contactPopup"
      v-model="contactPopup"
      :order="order"
      redirectPath="/orders"
    />
  </div>
</template>
<script>
import MenuCard from '@/components/MenuCard.vue'
import Times from '../helpers/times'
// import MyBtn from './generics/MyBtn.vue'
import _ from 'lodash'
import OrderContactDialog from '../components/dialogs/OrderPaymentDataDialog.vue'
import { serveTypesObject } from '@/data/serveTypes'

export default {
  name: 'bid-approval',
  props: { bid: { type: Object } },
  components: { MenuCard, OrderContactDialog },
  data() {
    return {
      contactPopup: false,
      chosenMenu: undefined,
      loadingCreateOrder: false,
      serveTypes: serveTypesObject,
      enum: ['credit', 'cash', 'transfer'],
      payTypes: [
        { key: 'credit', val: 'אשראי' },
        { key: 'cash', val: 'מזומן' },
        { key: 'transfer', val: 'העברה בנקאית' },
      ],
      form: {
        menus: [],
        client: {
          role: 6,
        },
        info: '',
        additionalInfo: '',
        serveInfo: '',
        payment: '',
        delivery: {
          day: '',
          startHour: '',
          address: '',
          info: '',
        },
        menu: null,
      },
    }
  },
  methods: {
    validated() {
      let form = this.form
      return (
        this.validateclient() &&
        this.chosenMenu >= 0 &&
        form.payment &&
        form.delivery &&
        form.delivery.day &&
        (form.delivery.type !== 'delivery' || form.delivery.address) &&
        form.delivery.startHour &&
        form.menus?.length
      )
    },
    validateclient() {
      return (
        this.form.client &&
        this.form.client.fullname &&
        this.form.client.role > 0
      )
    },
    async createOrder() {
      if (this.loadingCreateOrder) return
      this.loadingCreateOrder = true
      let { isConfirmed } = await this.$createSwal({
        title: 'ליצור הזמנה חדשה',
      })
      if (isConfirmed) {
        let order = _.cloneDeep(this.form)
        delete order.menus
        order['menu'] = _.cloneDeep(this.form.menus[this.chosenMenu])
        order.menu['info'] = this.form.menuInfo
        await this.$store.dispatch('order/store', order)
        await this.$store.dispatch('bid/destroy', {
          id: this.bid._id,
          toastOptions: { disabled: true },
        })
        this.contactPopup = true
        this.$timeoutSwal({ title: 'הזמנה נוצרה בהצלחה' })
      } else {
        this.loadingCreateOrder = false
      }
    },
  },
  computed: {
    orderDay() {
      return Times.extractDayString(this.form.delivery?.day)
    },
    orderDate() {
      return Times.extractDateString(this.form.delivery?.day)
    },
    orderTime() {
      return this.form.delivery?.startHour
    },
    order() {
      return this.$store.getters.order
    },
  },
  mounted() {
    if (!this.bid) return
    this.form = _.cloneDeep(this.bid)
    this.form.payment = 'cash'
    if (!this.form?.menus || this.form?.menus?.length === 1) {
      this.chosenMenu = 0
    }
  },
  created() {
    if (!this.bid) {
      this.$router.push('/bids')
    }
  },
}
</script>
<style>
.active {
  border: 5px solid var(--secondary);
}
</style>
