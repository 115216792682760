<template>
  <div>
    <my-dialog :title="product.title" v-model="openModal" :persistent="false">
      <template v-slot:content>
        <v-form>
          <v-row>
            <v-col>
              <v-text-field
                :label="$t('generics.name')"
                v-model="form.title"
                autofocus
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field :label="$t('generics.notes')" v-model="form.info" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="form.department"
                :items="departments"
                return-object
                :label="$t('generics.department')"
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                :label="$t('generics.catalogNumberText')"
                v-model="form.catalogNumber"
              />
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col>
              <v-text-field
                :label="$t('generics.price')"
                v-model="form.price"
                type="number"
                required
              />
            </v-col>
            <v-col>
              <v-file-input
                :label="$t('generics.uploadImage')"
                prepend-icon="mdi-camera"
                v-model="form.file"
                show-size
              />
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col class="mt-n4">
              <number-picker
                :label="$t('generics.category')"
                v-model="form.category"
                :min="1"
                :max="100"
                width="100%"
              />
            </v-col>
            <v-col>
              <v-combobox
                v-model="form.subCategory"
                :items="tagOptions"
                :label="$t('generics.subCategory')"
                item-text="text"
                :item-value="x => x"
                @keyup="addingTextToSubCategories"
              >
                <template v-slot:item="{ item: { _id, text } }">
                  <div style="width: 100%" class="d-flex justify-space-between">
                    <span>{{ text }} </span>
                    <v-btn @click="deleteSubCategory($event, _id)" icon>
                      <v-icon>mdi-close</v-icon></v-btn
                    >
                  </div>
                </template></v-combobox
              >
            </v-col>
          </v-row>
          <v-row class="center-all" >
            <v-checkbox v-model="form.box" :label="$t('generics.inBox')" @change="handleCheckboxChange('box')" />
            <v-checkbox class="ms-8" v-model="form.isSingleProduct"
             :label="$t('generics.isSingleProduct')" @change="handleCheckboxChange('isSingleProduct')" />
          </v-row>
          <v-row v-if="err">
            <v-col class="error--text text-center">
              {{ err }}
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn
          color="primary"
          v-text="$t('generics.save')"
          @click="submit"
          :disabled="!validateForm()"
        />
        <!-- <v-btn
          :disabled="!validatedForOrder"
          color="secondary"
          class="primary--text"
          @click="createOrder"
        > -->
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from './generics/MyDialog.vue'
import _, { cloneDeep } from 'lodash'
import NumberPicker from '@/components/generics/NumberPicker.vue'

export default {
  name: 'product-handler',
  components: { MyDialog, NumberPicker },
  props: { value: Boolean, product: Object, isNew: Boolean },
  data() {
    return {
      err: null,
      departments: [
        {
          text: this.$t('departments[1]'),
          value: 1,
        },
        {
          text: this.$t('departments[4]'),
          value: 4,
        },
        {
          text: this.$t('departments[7]'),
          value: 7,
        },
        {
          text: this.$t('departments[12]'),
          value: 12,
        },
        {
          text: this.$t('departments[9]'),
          value: 9,
        },
      ],
      form: {
        title: '',
        content: '',
        department: null,
        price: null,
        image: null,
        file: null,
        catalogNumber: null,
        box: false,
        isSingleProduct: false,
      },
    }
  },
  computed: {
    tagOptions() {
      const subCategory = this.form.subCategory || ''
      if (!typeof subCategory === 'string') return []
      return this.$store.getters.subCategory.filter(sub =>
        sub.text.includes(subCategory)
      )
    },
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    addingTextToSubCategories(e) {
      if (
        (e.keyCode >= 65 && e.keyCode <= 90) ||
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 33 && e.keyCode <= 47)
      ) {
        console.log(e.key)
        this.form.subCategory = e.target.value
      }
    },
    async deleteSubCategory(e, _id) {
      e.stopPropagation()
      await this.$store.dispatch('product/deleteSubCategory', { _id })
    },
    async submit() {
      const product = cloneDeep(this.form)
      product.department.key = product.department.value
      delete product.department.value
      if (product.subCategory?._id) {
        product.subCategory = product.subCategory._id
        product.subCategoryIsId = true
      }
      if (!product.subCategory) delete product.subCategory
      if (!this.isNew) {
        await this.$store.dispatch('product/update', product)
      } else {
        await this.$store.dispatch('product/store', product)
      }
      if (typeof product.subCategory === 'string') {
        this.$store.dispatch('product/subCategoryIndex', {
          toastOptions: { disabled: true },
        })
      }
      this.openModal = false
    },
    validateForm() {
      return this.form.title && this.form.price
    },
    createErr(msg) {
      this.err = msg
      setTimeout(() => {
        this.err = null
      }, 2000)
    },
    handleCheckboxChange(field) {
      if (this.form[field]) {
        this.form[field === 'box' ? 'isSingleProduct' : 'box'] = false;
      }
    },
  },
  mounted() {
    if (!this.isNew) {
      this.form = _.cloneDeep(this.product)
      this.form.department.value = this.form.department.key
    }
  },
}
</script>
