import dayjs from 'dayjs'
import Times from '../helpers/times'

const timeMixins = {
  methods: {
    getDateString(date) {
      return dayjs(date).format(Times.dateFormat)
    },
    getRTLDateString(date) {
      return dayjs(date).format(Times.dateFormatRTL)
    },
    calcRelativeDateString(otherDate) {
      if (!otherDate) return ''

      const timeNow = Date.now()
      const date = dayjs(otherDate)

      const daysDiff = date.diff(timeNow, 'day')

      if (daysDiff === 0) return date.format('H:mm')
      if (daysDiff === -1) return date.format('H:mm') + ' אתמול'
      return date.format('D.M.YY H:mm')
    },
    areSameDates(date1, date2) {
      return dayjs(date1).isSame(dayjs(date2))
    },
    isAfterYesterdayAt10(date) {
      const yesterdayAt10 = dayjs().hour(10).minute(0).subtract(1, 'day')
      return dayjs(date).isAfter(yesterdayAt10)
    },
  },
}

export default timeMixins
