import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import he from 'vuetify/lib/locale/he'

Vue.use(Vuetify)

export default new Vuetify({
  rtl: true,
  lang: {
    locales: { he },
    current: 'he',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#C29670',
        secondary: '#4a3f31',
        secondaryLight: '#e7dac8',
        accent: '#82b1ff',
        error: '#f39e9e',
        info: '#2196f3',
        success: '#68dd83',
        warning: '#ffc107',
        lightGrey: '#b8b7b7',
        greyHighlight: '#dad9d9',
      },
    },
  },
})
