<template>
  <div>
    <filter-app-bar
      v-if="isFilterDefaultValueLoaded"
      @updateDeliveryDate="updateDeliveryDate"
      @updateStart="updateStart"
      @updateEnd="updateEnd"
      :defaultStart="start"
      :defaultEnd="end"
      :defaultIsDeliveryDateValue="isDeliveryDate"
      :defaultFilterObj="defaultFilter"
      :disabled="!initialDataFetchFinish"
    />
    <v-container v-if="isFilterDefaultValueLoaded">
      <h2 class="text-center text-h5">
        {{ !endDate ? startDate : `${endDate} - ${startDate}` }}
      </h2>
      <app-title>{{ $t('collection.index') }}</app-title>
      <v-card v-if="orders.length" id="orders-table" class="pa-0" rounded="lg">
        <v-card-text>
          <collection-table :orders="orders" />
        </v-card-text>
      </v-card>
      <div v-else class="center-all mt-15">
        <h3>{{ $t('order.noOrders') }}</h3>
      </div>
    </v-container>
  </div>
</template>
<script>
import CollectionTable from '../components/CollectionTable.vue'
import FilterAppBar from '../layouts/FilterAppBar.vue'
import AppTitle from '../components/AppTitle.vue'
import genericsServices from '../services/generics'
import dayjs from 'dayjs'
import Times from '../helpers/times'
import FiltersCaching from '@/services/FiltersCaching.js'

export default {
  name: 'collection',
  components: { CollectionTable, AppTitle, FilterAppBar },
  data() {
    return {
      loading: false,
      search: '',
      start: null,
      end: null,
      isDeliveryDate: null,
      startDate: '',
      endDate: '',
      initialDataFetchFinish: false,
      initialDataFetchStart: false,
    }
  },
  computed: {
    isFilterDefaultValueLoaded() {
      return this.start && this.end && this.isDeliveryDate !== null
    },
    orders() {
      const orders = this.$store.getters.orders
      orders.forEach(order => {
        order['isPicked'] = order?.menu?.products.every(
          p => p.isPicked === true
        )
      })
      return orders
      // genericsServices.filterOrders(
      //   orders,
      //   this.start,
      //   this.end,
      //   this.isDeliveryDate
      // )
    },
    defaultFilter() {
      const today = dayjs()
      return {
        start: today.format(Times.dateFormat),
        end: today.format(Times.dateFormat),
      }
    },
  },
  methods: {
    async loadData() {
      if (this.initialDataFetchStart) return
      this.initialDataFetchStart = true
      await this.orderIndex()
      this.initialDataFetchFinish = true
    },
    async orderIndex() {
      await this.$store.dispatch('order/index', {
        min: this.start,
        max: this.end,
      })
    },
    updateStart(v) {
      this.start = v
      this.startDate = v ? dayjs(v).format('DD/MM/YYYY') : ''
    },
    updateEnd(v) {
      this.end = v
      this.endDate = v ? dayjs(v).format('DD/MM/YYYY') : ''
    },
    updateDeliveryDate(v) {
      this.isDeliveryDate = v
    },
  },
  async mounted() {
    const { start, end, isDeliveryDate } = FiltersCaching.get({
      type: 'all',
    })
    this.start = start || this.defaultFilter.start
    this.end = end || this.defaultFilter.end
    this.isDeliveryDate = isDeliveryDate !== undefined ? isDeliveryDate : true
    await this.loadData()
  },
  watch: {
    start(value) {
      FiltersCaching.save({ type: 'all', key: 'start', value })
      if (!this.initialDataFetchFinish) return
      this.orderIndex()
    },
    end(value) {
      FiltersCaching.save({ type: 'all', key: 'end', value })
      if (!this.initialDataFetchFinish) return
      this.orderIndex()
    },
    isDeliveryDate(value) {
      FiltersCaching.save({ type: 'all', key: 'isDeliveryDate', value })
    },
  },
}
</script>
