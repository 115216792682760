<template>
  <v-container>
    <h3 class="text-center mt-10">{{ $t('client.handlerTitleclients') }}</h3>
    <v-data-table
      :headers="headers"
      :items="clients"
      :loading="loading"
      :search="search"
      :items-per-page="20"
      id="orders-table"
      class="mt-2 app-table"
      :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          :label="$t('generics.search')"
          class="px-4 primary"
          prepend-icon="mdi-magnify"
          color="secondary"
        />
      </template>
      <template v-slot:item.address="{ item }">
        <span>{{ parseAddress(item) }} </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>

        <v-progress-circular
          v-if="loadingDeleteUser === item._id"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-icon v-else :disabled="!!loadingDeleteUser" @click="deleteItem(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <Client-handler
      v-if="createClientDialog"
      v-model="createClientDialog"
      :is-new="true"
    />
    <Client-handler
      v-if="updateClientDialog"
      v-model="updateClientDialog"
      :is-new="false"
      :client="chosen"
    />
    <div class="floating-btn">
      <v-btn fab big class="mx-2" color="green" @click="excelDialog = true">
        <v-icon color="white">mdi-microsoft-excel</v-icon>
      </v-btn>
      <v-btn
        fab
        big
        class="mx-2"
        color="secondary"
        @click="createClientDialog = true"
      >
        <v-icon color="primary">mdi-plus</v-icon>
      </v-btn>
    </div>
    <drag-file
      :handle-upload="handleExcel"
      v-model="excelDialog"
      :title="$t('generics.uploadExcel')"
    />
  </v-container>
</template>

<script>
import ClientHandler from '@/components/ClientHandler'
import DragFile from '@/components/generics/DragFile'

export default {
  name: 'ClientsPage',
  components: { ClientHandler, DragFile },
  data() {
    return {
      loading: false,
      createClientDialog: false,
      updateClientDialog: false,
      excelDialog: false,
      chosen: null,
      search: '',
      loadingDeleteUser: false,
      headers: [
        { text: this.$t('userDetails.fullName'), value: 'fullname' },
        // { text: this.$t('generics.email'), value: 'email' },
        { text: this.$t('generics.mobile'), value: 'phone' },
        // { text: this.$t('userDetails.id'), value: 'userId' },
        { text: this.$t('client.company'), value: 'company' },
        { text: this.$t('generics.address'), value: 'address' },
        { text: this.$t('generics.contact'), value: 'address.contact' },

        // { text: this.$t('generics.socialId'), value: 'socialId' },
        { text: this.$t('generics.actions'), value: 'actions' },
      ],
    }
  },
  computed: {
    clients() {
      return this.$store.getters.clients
    },
  },
  methods: {
    parseAddress(item) {
      return `${item?.address?.city || ''} ${item?.address?.street || ''} ${
        item?.address?.houseNumber || ''
      } ${item?.address?.postalCode || ''}`
    },
    async editItem(u) {
      this.chosen = u
      this.updateClientDialog = true
    },
    async deleteItem(item) {
      this.loadingDeleteUser = item._id
      const isOrders = await this.$store.dispatch('client/order', {
        _id: item._id,
        toastOptions: { disabled: true },
      })
      if (!!isOrders.length) {
        this.$errorToast(
          `${this.$t(`order.${isOrders[1] ? 'index' : 'one'}`)}: ${isOrders.map(
            order => order.orderNum
          )}`
        )
        this.$errorToast(this.$t('client.deleteBlock'))
        this.loadingDeleteUser = false
        return
      }

      let { isConfirmed } = await this.$createSwal({
        title: this.$t('generics.delete'),
      })
      if (isConfirmed) {
        await this.$store.dispatch('client/destroy', item._id)
      }
    },
    async handleExcel(file) {
      await this.$store.dispatch('client/xl-upload', file)
      await this.$store.dispatch('client/index')
      this.excelDialog = false
    },
  },
  async mounted() {
    if (!this.clients.length) {
      await this.$store.dispatch('client/index')
    }
  },
}
</script>
