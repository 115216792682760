<template>
  <div style="margin-top: 30px; position: absolute; bottom: 0; right: 0">
    <div class="center" v-if="pageCounter">- {{ pageCounter }} -</div>
    <div
      :style="`
      padding-bottom: 6px !important;
      padding: 0 50px 6px 50px !important;
    `"
    >
      <h5 class="center">{{ $t('cancelPolicy.title') }}</h5>
      <p class="center-cation mt-2" v-html="$t('cancelPolicy.text')"></p>
    </div>
    <footer class="footer">
      <ul>
        <li>{{ $t('cancelPolicy.companyName') }}</li>
        <li>{{ `| ${$t('cancelPolicy.companyNameSecond')} |` }}</li>
        <li>{{ `${$t('client.userId')} 514855089 ` }}</li>
      </ul>
      <ul>
        <li>{{ $t('cancelPolicy.address') }}</li>
        <li>| 03-9342936 |</li>
        <li>nugatin@gmail.com</li>
      </ul>
    </footer>
  </div>
</template>
<script>
export default {
  name: 'OrderFooter',
  props: ['pageCounter'],
}
</script>
<style lang="scss" scoped>
.center {
  text-align: center;
}

.center-cation {
  text-align: center;
  font-size: 12px;
  padding: 0px 12px;
}

.footer {
  background: black;
  font-size: 20px;
  padding: 16px;
  color: var(--secondaryLight);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px -60px -2px -60px;

  ul {
    display: flex;

    li {
      padding: 0 8px;
      list-style: none;
    }
  }
}
</style>
