<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="6">
        <h2>לקוח</h2>
      </v-col>
    </v-row>
    <v-row class="flex-break">
      <v-col>
        <v-combobox
          v-model="form.client.fullname"
          :items="clients"
          item-text="fullname"
          label="בחירת לקוח"
          return-object
          :item-value="x => x"
          @change="onPickUser"
          solo
          outlined
          clearable
          @click:clear="clearClient"
          :errorMessages="
            showErrorForBid(form.client.fullname) ||
            showErrorForOrder(form.client.fullname)
          "
        >
          <template v-slot:item="{ item: { fullname, phone } }">
            {{ `${fullname || ''} - ${phone || ''}` }}
          </template>
        </v-combobox>
      </v-col>
      <v-col>
        <v-text-field
          :disabled="!isNewUser"
          solo
          outlined
          :label="$t('generics.mobile')"
          v-model="form.client.phone"
          :maxlength="11"
        />
      </v-col>

      <v-col>
        <v-select
          :error-messages="showErrorForOrder(form.paymentMethod)"
          v-model="form.paymentMethod"
          :items="paymentMethod"
          :label="$t('order.paymentMethod')"
          persistent-hint
          single-line
          solo
          outlined
        />
      </v-col>
      <v-col>
        <v-text-field
          :error-messages="
            (form.paymentMethod === 'deferred' || '') &&
            showErrorForOrder(form.infoOnPayment)
          "
          background-color="white"
          outlined
          :label="$t('generics.infoOnPayment')"
          v-model="form.infoOnPayment"
        />
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="my-4">
      <v-col>
        <h2>{{ $t('generics.supply') }}</h2>
      </v-col>
    </v-row>
    <v-row class="flex-break">
      <v-col>
        <v-select
          solo
          outlined
          :label="$t('order.deliveryTypes')"
          :items="deliveryTypes"
          item-text="title"
          item-value="name"
          v-model="form.delivery.type"
          :error-messages="showErrorForOrder(form.delivery.type)"
        />
      </v-col>
      <v-col>
        <my-date-picker
          :start="today"
          v-model="form.delivery.day"
          :errorMessages="
            showErrorForBid(form.delivery.day) ||
            showErrorForOrder(form.delivery.day)
          "
          :label="$t('date.supplyDate')"
        />
      </v-col>
      <v-col cols=" 2" v-if="selectedDay">
        <v-text-field v-model="selectedDay" solo outlined disabled />
      </v-col>
      <v-col style="position: relative">
        <v-radio-group
          v-if="form.delivery.type === 'delivery'"
          v-model="selectedTimeFormat"
          class="v-input-hours"
        >
          <v-radio
            v-for="value in timeFormats"
            :key="value"
            :label="`${value}`"
            :value="value"
          ></v-radio>
        </v-radio-group>
        <v-select
          solo
          outlined
          :label="$t('date.supplyStartHour')"
          :items="validDeliveryTimes"
          :disabled="!validDeliveryTimes"
          v-model="form.delivery.startHour"
          :error-messages="showErrorForOrder(form.delivery.startHour)"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          :items="serveTypes"
          solo
          outlined
          v-model="form.serveInfo"
          item-text="val"
          item-value="key"
          :label="$t('delivery.servingType')"
          :error-messages="showErrorForOrder(form.serveInfo)"
        />
      </v-col>
    </v-row>

    <v-row class="flex-break">
      <v-col>
        <v-text-field
          color="secondary"
          background-color="primary"
          outlined
          v-model="form.info"
          :label="`${$t('order.info')} ${$t('order.infoAuthMsg')}`"
        />
      </v-col>
      <v-col>
        <v-text-field
          color="secondary"
          background-color="primary"
          outlined
          :label="$t('order.additionalInfo')"
          v-model="form.additionalInfo"
        />
      </v-col>
    </v-row>
    <v-row class="flex-break" v-if="form.delivery.type == 'delivery'">
      <v-col>
        <v-textarea
          type="address"
          v-model="form.delivery.address"
          auto-grow
          background-color="white"
          outlined
          rows="3"
          row-height="3"
          :label="$t('order.address')"
          :error-messages="showErrorForOrder(form.delivery.address)"
        />
      </v-col>
      <v-col>
        <v-textarea
          :label="$t('delivery.contacts')"
          auto-grow
          background-color="white"
          outlined
          rows="3"
          row-height="13"
          v-model="form.delivery.info"
        />
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="my-2" style="position: relative">
      <v-col class="d-flex align-center flex-column">
        <h2>{{ isEditOrder ? $t('order.one') : $t('menu.title') }}</h2>
        <span
          class="text-center error--text mb-n7 mt-2"
          v-if="!menuProductsLen"
          >{{ showErrorForBid(0) || showErrorForOrder(0) }}</span
        >
      </v-col>
      <span v-if="isEditOrder" style="position: absolute; left: 0">
        <span class="font-weight-bold">{{ $t('order.num') }}: </span
        >{{ form.orderNum }}</span
      >
    </v-row>
    <!-- <my-btn v-if="!isEditOrder" :disabled="!validateclient" @click="addMenu" /> <div v-else /> -->
    <v-row>
      <v-col>
        <!-- <v-sheet
          class="mx-auto secondaryLight d-flex justify-center"
          max-width="100%"
        >
          <v-slide-group show-arrows class="d-flex justify-space-between"> -->
        <!-- <v-slide-item v-for="(m, idx) in form.menus" :key="m.key"> -->
        <!-- v-for="(m, idx) in form.menus"
          :key="m.key" -->
        <menu-card
          v-if="form.menus[0]"
          v-model="form.menus[0]"
          :canSend="validateclient"
          :canDelete="!isEditOrder"
          @showLinks="openPdf"
          @update="updateMenu"
          :client="form.client"
        />
        <!-- :menu="form.menus[0]" -->
        <!-- :idx="idx" -->
        <!-- :localKey="m.key" -->
        <!-- @destroy="removeMenu" -->
        <!-- </v-slide-item> -->
        <!-- </v-slide-group>
        </v-sheet> -->
      </v-col>
    </v-row>
    <v-row class="center-all">
      <v-col cols="6">
        <v-text-field
          :label="$t('generics.notesOnMenu')"
          v-model="form.menuInfo"
          outlined
          background-color="white"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          :label="$t('generics.internalNotes')"
          v-model="form.internalNotes"
          outlined
          background-color="white"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          :label="$t('generics.internalNotesShipping')"
          v-model="form.internalNotesShipping"
          outlined
          background-color="white"
        />
      </v-col>
    </v-row>
    <div class="center-all" style="gap: 20px">
      <div>
        <v-btn
          v-if="isEditOrder && form.linkToPayment"
          @click="copyLink(form.linkToPayment)"
          class="me-4"
          color="primary"
          style="width: 200px"
        >
          <span
            >{{ $t('generics.copyPayLink') }}
            <v-icon>mdi-link-variant </v-icon></span
          >
        </v-btn>
        <v-btn
          color="secondary"
          class="primary--text"
          @click="save"
          :disabled="!validated || savingLoading"
          style="
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: 1px;
          "
          >{{ $t('generics.save') }}
        </v-btn>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="secondary"
              :disabled="!validated || savingLoading"
              class="primary--text px-1"
              @click="save({ download: true })"
              style="
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                min-width: 0;
              "
              ><v-icon>mdi-download</v-icon></v-btn
            ></template
          >
          <span>{{ $t('generics.saveAndDownload') }}</span>
        </v-tooltip>
      </div>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="isEditOrder ? on : null">
            <v-btn
              :disabled="isEditOrder || !validatedForOrder"
              color="primary"
              class="black--text"
              @click="createOrder"
            >
              {{ $t('order.createOrder') }}
            </v-btn></span
          >
        </template>
        <span>{{ $t('generics.orderHasBeenCreated') }}</span>
      </v-tooltip>
      <v-btn
        v-if="form._id"
        @click="deleteOrder"
        color="red"
        style="width: 70px"
      >
        <v-icon color="white">mdi-delete</v-icon>
      </v-btn>
    </div>
    <OrderPaymentDataDialog
      :value="showLinkDialog"
      :order="form"
      :menuId="selectedMenuId"
      :isBid="true"
      @input="showLinkDialog = $event"
    />
    <v-overlay v-model="showMultiUserWarning" :opacity="0.8">
      <v-card max-width="400" class="pa-4 text-center" color="warning">
        <v-card-title class="justify-center">
          <v-icon large class="mr-2">mdi-alert</v-icon>
        </v-card-title>
        <v-card-text class="text-h6 white--text">
          {{
            $t('warnings.multipleUsers', { count: activeUsersCount })
          }} </v-card-text
        ><v-card-actions class="center-all">
          <v-btn @click="$router.back()">
            {{ $t('generics.backToLastPage') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-container>
</template>
<script>
import MyDatePicker from './generics/MyDatePicker.vue'
import MenuCard from './MenuCard.vue'
// import MyBtn from './generics/MyBtn.vue'
import uniqid from 'uniqid'
import _, { cloneDeep } from 'lodash'
import Times from '../helpers/times'
import dayjs from 'dayjs'
import OrderPaymentDataDialog from './dialogs/OrderPaymentDataDialog.vue'
import genericsServices from '../services/generics'
import validationMixins from '@/mixins/validation'
import orderBidHandlerMixin from '@/mixins/orderBidHandler'
import { serveTypesList } from '../data/serveTypes'
import { io } from 'socket.io-client'

export default {
  name: 'order-bid-handler',
  components: {
    MyDatePicker,
    MenuCard,
    OrderPaymentDataDialog,
    // , MyBtn
  },
  mixins: [validationMixins, orderBidHandlerMixin],
  data() {
    return {
      saveInterval: null,
      selectedTimeFormat: 1,
      timeFormats: [1, 1.5, 2, 2.5, 3],
      payTypes: [
        { key: 'credit', val: 'אשראי' },
        { key: 'cash', val: 'מזומן' },
        { key: 'transfer', val: 'העברה בנקאית' },
      ],
      users: [],
      user: {},
      isEditOrder: false,
      isNew: true,
      showLinkDialog: false,
      selectedMenuId: null,
      paymentMethod: [
        { text: this.$t('generics.deferred'), value: 'deferred' },
        { text: this.$t('generics.cash'), value: 'cash' },
      ],
      serveTypes: serveTypesList,
      deliveryTypes: [
        {
          title: this.$t('delivery.index'),
          name: 'delivery',
        },
        {
          title: this.$t('generics.selfPickUp'),
          name: 'self',
        },
      ],
      menu: null,
      form: {
        internalNotesShipping: '',
        internalNotes: '',
        paymentMethod: 'cash',
        client: {
          fullname: '',
          role: 5,
        },
        info: '',
        additionalInfo: '',
        serveInfo: '',
        delivery: {
          day: '',
          startHour: '',
          address: '',
          info: '',
          type: '',
        },
        menus: [],
        // payment: '',
      },
      times: {
        self: {
          week: null,
          friday: null,
        },
        delivery: {
          week: null,
          friday: null,
        },
      },
      isFirstTimeUpdating: true,
      dataSaved: false,
      mounted: false,
      recordInDB: {},
      savingLoading: false,
      socket: null,
      showMultiUserWarning: false,
      activeUsersCount: 0,
      unloadHandler: null, // Add a reference to the handler
    }
  },
  methods: {
    copyLink(link) {
      navigator.clipboard.writeText(link)
    },
    getHoursDifference(timeRangeString) {
      const [endTime, startTime] = timeRangeString.split(' - ')
      const startDateTime = dayjs(`2023-01-01 ${startTime}`)
      const endDateTime = dayjs(`2023-01-01 ${endTime}`)
      const duration = endDateTime.diff(startDateTime, 'hours', true)
      return duration
    },
    openPdf() {
      window.open(this.pdfUrl, '_blank')
    },
    showErrorForOrder(value) {
      if (this.isNew || value) return ''
      return 'שדה חובה ליצירת/שמירת הזמנה'
    },
    showErrorForBid(value) {
      if (!this.isNew || value) return ''
      return 'שדה חובה ליצירת הצעת מחיר'
    },
    async submit() {
      if (this.isNewUser) {
        await this.$store.dispatch('client/store', this.form.client)
        this.form.client = this.$store.getters.client
      }
      const form = _.cloneDeep(this.form)
      form.menus = this.removeUnnecessaryDataFromMenus(form.menus)
      if (this.isEditOrder) {
        form['menu'] = _.cloneDeep(this.form.menus[0])
        delete form.menus
        if (form.paymentData?.docNum) delete form.paymentData
        form.menuChanged = !!this.mxIcountNeedToUpdate()
        const res = await this.$store.dispatch('order/update', form)
        if (res?.linkToPayment) this.form.linkToPayment = res.linkToPayment
        this.recordInDB = _.cloneDeep(form)
      } else {
        if (this.isNew) {
          const newForm = await this.$store.dispatch('bid/store', form)
          //update data
          this.isNew = false
          this.form.menus = this.form.menus.map((m, i) => {
            m._id = newForm.menus[i]?._id
            return m
          })
          this.form._id = newForm._id
        } else {
          await this.$store.dispatch('bid/update', form)
        }
      }
    },
    async showLinks(menuKey) {
      await this.submit()
      const menu = this.form.menus.find(m => m.key == menuKey)
      if (!menu) return
      this.selectedMenuId = menu._id
      this.showLinkDialog = true
    },
    async save({ download }) {
      this.savingLoading = true
      await this.submit()
      if (download) {
        this.openPdf()
      }
      this.dataSaved = true
      this.savingLoading = false
    },
    removeUnnecessaryDataFromMenus(menus) {
      return menus.map(m => {
        m.products = m.products.map(p => {
          delete p._id
          return p
        })

        return m
      })
    },
    getUserType(role = 5) {
      return role === 5 ? this.$t('client.business') : this.$t('client.private')
    },
    addMenu() {
      let id = uniqid()
      if (this.form.menus.length < 5) {
        this.form.menus.push({
          key: id,
          title: this.createDefaultMenuTitle(),
          info: '',
          products: [],
          searchMode: true,
        })
      }
    },
    // removeMenu(key) {
    //   this.form.menus = this.form.menus.filter(m => m.key !== key)
    // },
    createDefaultMenuTitle() {
      let str = ''
      str += `${this.$t('menu.title')} `
      if (this.form.client.fullname) {
        str += this.form.client.fullname
      }
      if (this.form.delivery.day) {
        str += ' ' + dayjs(this.form.delivery.day).format(Times.dateFormatRTL)
      }
      return str
    },
    onPickUser(item) {
      if (!item.fullname) return

      this.form.paymentMethod = item?.defaultPaymentMethod || ''
      this.form.client = item
      const { email } = item
      const { street, houseNumber, city, postalCode, contact } = item.address
      this.form.info = [contact, email].filter(x => x).join(' ')
      this.form.delivery.address = [street, houseNumber, city, postalCode]
        .filter(x => x)
        .join(' ')
    },
    clearClient() {
      this.form.client = {
        fullname: '',
        role: 5,
      }
    },
    //this method listens to every change inside MenuCard and update the wanted menu
    updateMenu(m) {
      // this.form.menus.forEach((el, i) => {
      //   if (el.key === m.key) {
      //   }
      // })
      // m.menu['key'] = m.key
      this.form.menus[0] = { ...m.menu }
    },
    async deleteOrder() {
      /////\
      const { isConfirmed } = await this.$createSwal({
        title: this.$t('order.deleteOrderMsg'),
      })
      if (!isConfirmed) return
      if (this.isEditOrder) {
        await this.$store.dispatch('order/destroy', this.form._id)
      } else {
        await this.$store.dispatch('bid/destroy', { id: this.form._id })
      }
      this.dataSaved = true
      this.$router.go(-1)
    },
    async createOrder() {
      let { isConfirmed } = await this.$createSwal({
        title: '?' + this.$t('order.createNewMsg'),
      })
      if (isConfirmed) {
        let order = _.cloneDeep(this.form)
        order['menu'] = _.cloneDeep(this.form.menus[0])
        delete order.menus
        const newOrder = await this.$store.dispatch('order/store', order)
        this.$timeoutSwal({ title: this.$t('order.created'), timer: 800 })
        this.selectedMenuId = order['menu']?._id
        this.showLinkDialog = true
        this.form = newOrder
        this.form['menus'] = [newOrder.menu]
        this.$forceUpdate()
        this.isEditOrder = true
        setTimeout(() => {
          this.dataSaved = true
        }, 1)
        this.recordInDB = _.cloneDeep(order)
      }
    },
    setDeliveryHours() {
      this.times.delivery.week = genericsServices.mergeArraysTogther(
        Times.getRangesInRange('06:00', '20:00', 60 * this.selectedTimeFormat),
        Times.getRangesInRange('06:30', '20:00', 60 * this.selectedTimeFormat)
      )
      this.times.delivery.friday = genericsServices.mergeArraysTogther(
        Times.getRangesInRange('06:00', '14:00', 60 * this.selectedTimeFormat),
        Times.getRangesInRange('06:30', '14:00', 60 * this.selectedTimeFormat)
      )
      this.times.self.week = Times.getTimesInRange('06:00', '16:30', 30)
      this.times.self.friday = Times.getTimesInRange('06:00', '14:00', 30)
    },
    initializeSocket() {
      this.socket = io() // No need for query parameters

      this.socket.on('connect', () => {
        this.socket.emit('pageView', window.location.href)
      })

      this.socket.on('multipleUsers', ({ count }) => {
        this.activeUsersCount = count
        this.showMultiUserWarning = true
      })

      // Use 'beforeunload' event
      this.unloadHandler = () => {
        if (this.socket) {
          this.socket.emit('cleanup')
        }
      }
      window.addEventListener('beforeunload', this.unloadHandler)
    },
    cleanupSocket() {
      if (this.socket) {
        this.socket.emit('cleanup')
        this.socket.disconnect()
        this.socket = null
      }
      // Remove the 'beforeunload' listener
      if (this.unloadHandler) {
        window.removeEventListener('beforeunload', this.unloadHandler)
        this.unloadHandler = null
      }
    }
  },
  beforeDestroy() {
    window.onbeforeunload = null
    if (this.saveInterval) clearInterval(this.saveInterval)
    // Remove the unload listener
    window.removeEventListener('unload', this.cleanupSocket)
    this.cleanupSocket()
  },
  async beforeRouteLeave(_, __, next) {
    if (this.dataSaved) {
      this.cleanupSocket()
      next()
      return
    }
    let { isConfirmed } = await this.$createSwal({
      title: this.$t('order.leaveMsg'),
    })
    if (isConfirmed) {
      this.cleanupSocket()
      next()
    } else {
      next(false)
    }
  },
  created() {
    this.setDeliveryHours()
    this.initializeSocket()
    // Removed the unload event listener
  },
  beforeDestroy() {
    window.onbeforeunload = null
    if (this.saveInterval) clearInterval(this.saveInterval)
    if (this.socket) this.socket.disconnect()
  },
  computed: {
    pdfUrl() {
      let link = `${document.location.origin}/orders/pdf/${this.form._id}`
      if (!this.isEditOrder) {
        return `${link}?menu=${this.form?.menus[0]?._id}`
      }
      return link
    },
    isClientDateTrue() {
      return this.form.client.fullname && this.form.delivery.day
    },
    menuProductsLen() {
      return this.form.menus?.[0]?.products?.length
    },
    isNewUser() {
      return !this.form.client?._id
    },
    validated() {
      return !!(
        this.validateclient &&
        this.form.delivery.day &&
        this.menuProductsLen &&
        (!this.isEditOrder || this.validatedForOrder)
      )
    },

    validateclient() {
      return !!(
        this.form.client &&
        this.form.client.fullname &&
        this.form.client.role > 0
      )
    },
    validatedForOrder() {
      let form = this.form
      return !!(
        this.validateclient &&
        form.paymentMethod &&
        // form.payment &&
        form.delivery &&
        form.delivery.day &&
        (form.delivery.type !== 'delivery' || form.delivery.address) &&
        form.delivery.startHour &&
        form.menus?.length &&
        !this.isNew
      )
    },
    clients() {
      return this.$store.getters.clients
    },
    today() {
      return dayjs().format(Times.dateFormat)
    },
    selectedDay() {
      const date = dayjs(this.form.delivery.day)
      return date.isValid() ? this.$t(`days[${date.format('d')}].name`) : null
    },
    isFormValidatedComputed() {
      const type = this.form.delivery?.type
      if (!type) return false
      let isDeliveryValid = type == 'self' ? true : !!this.form.delivery.address

      return (
        isDeliveryValid &&
        !!this.form.delivery.day &&
        !!this.form.delivery.startHour &&
        !!this.form.serveInfo
      )
    },
    validDeliveryTimes() {
      const date = dayjs(this.form.delivery.day)
      const type = this.form.delivery.type
      if (!date.isValid() || !type) return null

      const day = parseInt(date.format('d'))
      //not friday or shabbat
      if (day < 5) return this.times[type].week

      //if friday
      if (day == 5) return this.times[type].friday

      //is shabbat
      return null
    },
  },
  async mounted() {
    window.addEventListener('beforeunload', event => {
      if (this.dataSaved) return
      event.returnValue = ''
    })
    const { isNew, bid, isEditOrder } = this.$route.params
    const { menus, clients } = this.$store.getters

    if (!clients?.length) await this.$store.dispatch('client/index')
    if (!menus?.length) this.$store.dispatch('menu/index')
    if (bid && !bid?.client) {
      bid.client = {
        fullname: '',
        role: 5,
      }
    }
    this.recordInDB = _.cloneDeep(bid)
    if (isNew === false) {
      this.form = { ...this.form, ...bid }
      this.form.delivery['day'] = Times.extractDateString(
        bid?.delivery?.day,
        true
      )
      this.isNew = false
      // this.form.menus = this.form.menus.map(m => {
      //   m.key = uniqid()
      //   return m
      // })
      this.selectedTimeFormat = this.getHoursDifference(
        this.form.delivery.startHour
      )
    }
    if (isEditOrder) {
      this.isEditOrder = isEditOrder
      this.menu = bid.menu
      this.form.menus[0] = bid.menu
    }
    if (!this.form?.menus?.length) {
      this.addMenu()
      this.form.menus.title = this.createDefaultMenuTitle()
    }
    this.dataSaved = true
    setTimeout(() => {
      this.mounted = true
    }, 1000)
    if (!this.isEditOrder) {
      this.saveInterval = setInterval(async () => {
        if (!this.validated || this.showMultiUserWarning) return
        await this.save({ download: false })
      }, 60000)
    }
  },
  async beforeRouteLeave(_, __, next) {
    if (this.dataSaved) {
      next()
      return
    }
    let { isConfirmed } = await this.$createSwal({
      title: this.$t('order.leaveMsg'),
    })
    if (isConfirmed) {
      next()
    } else {
      next(false)
    }
  },
  watch: {
    selectedTimeFormat() {
      this.setDeliveryHours()
    },
    isClientDateTrue() {
      if (!this.form.menus?.[0] || this.form?._id) return
      this.form.menus[0].title = this.createDefaultMenuTitle()
    },
    'form.delivery.type'() {
      if (this.isFirstTimeUpdating) {
        this.isFirstTimeUpdating = false
        return
      }
      this.form.delivery.startHour = ''
      this.form.delivery.address = ''
    },
    'form.client.phone'(e) {
      if (e?.length === 3) {
        this.form.client.phone = e + '-'
      }
    },
    form: {
      deep: true,
      handler(v) {
        if (!this.mounted) return
        this.dataSaved = false
      },
    },
  },
}
</script>
<style scoped>
.v-input-hours >>> .v-input--radio-group__input {
  align-items: baseline;
  flex-direction: row;
  justify-items: center;
  direction: ltr;
}
.v-input-hours {
  position: absolute;
  top: -39px;
  font-size: 12px;
  left: 50%;
  transform: translateX(-50%);
}
.v-input-hours >>> label {
  font-size: 14px !important;
}
</style>
