<template>
  <v-simple-table style="margin-top: -4px; margin-bottom: 16px">
    <template v-slot:default>
      <tbody>
        <tr class="table-footer pa-3 secondaryLightBackground">
          <td
            style="text-align: end !important; padding: 3px 16px !important"
            class="bold"
          >
            <span style="padding: 0 10px !important">
              <span> {{ $t('generics.sum') }} </span>
              <span
                style="
                  margin-left: 5px;
                  margin-right: 4px;
                  display: inline-block;
                  border-bottom: 1px solid black;
                  height: 21px;
                "
              >
                {{ $t('generics.including') }}
              </span>
              <span> {{ $t('generics.vat') }}:</span>
            </span>
            <span>
              {{ formateNumbers(orderTotalPrice) }}
              ₪
            </span>
          </td>
        </tr>
        <tr class="table-footer pa-3 lightGreyBackground" v-if="isDiscount">
          <td
            style="text-align: end !important; padding: 3px 16px !important"
            class="bold"
          >
            <span style="padding: 0 10px !important">
              {{ $t('generics.discount') }}:
            </span>
            <span>
              {{ menu && menu.discount }}
              %
            </span>
          </td>
        </tr>
        <tr
          class="table-footer pa-3 secondaryLightBackground"
          :class="`${isRound && !isDiscount ? 'whiteBackground' : ''}`"
          v-if="isRound"
        >
          <td
            style="text-align: end !important; padding: 3px 16px !important"
            class="bold"
          >
            <span style="padding: 0 10px !important">
              {{ $t('generics.rounded') }}:
            </span>
            <span>
              {{ calculateDiscount.rounded }}
              ₪</span
            >
          </td>
        </tr>
        <tr
          class="table-footer pa-3 lightGreyBackground"
          v-if="isRound || isDiscount"
        >
          <td
            style="text-align: end !important; padding: 3px 16px !important"
            class="bold"
          >
            <span style="padding: 0 10px !important">
              {{ $t('bid.priceAfterDiscount') }}:
            </span>
            <span> {{ formateNumbers(+calculateDiscount.total) }} ₪ </span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import { calculateRounded } from '../../helpers/calculateRounded'
import { formateNumbers } from '@/helpers/formatter'

export default {
  name: 'OrderTableFooter',
  props: {
    menu: Object,
    orderTotalPrice: [String, Number],
  },

  methods: {
    formateNumbers,
  },
  computed: {
    isRound() {
      return !['0.00 -', '0.00'].includes(this.calculateDiscount.rounded)
    },
    isDiscount() {
      return !!this.menu?.discount
    },
    calculateDiscount() {
      const discount = this.menu?.discount ?? 0
      const amount = this.orderTotalPrice ?? 0
      return calculateRounded(amount, discount)
    },
  },
}
</script>
<style lang="scss" scoped>
.secondaryLightBackground {
  background-color: var(--secondaryLight) !important;
}
.whiteBackground {
  background-color: white !important;
}
.lightGreyBackground {
  background-color: var(--light-grey) !important;
}
.table-footer {
  background-color: var(--light-grey);
  table-layout: fixed;

  td {
    width: 13%;
  }
}

.bold {
  font-weight: 700;
}
</style>
