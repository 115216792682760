<template>
  <v-card
    class="mx-auto"
    outlined
    min-height="300"
    :style="`${
      !menu.products.length
        ? `border: ${$vuetify.theme.themes.light.error} solid 2px`
        : ''
    }`"
  >
    <v-card-title class="primary d-flex flex-column justify-center">
      <div class="d-flex justify-center align-center">
        <span class="small-text text-h6">{{ menu.title }}</span>
        <v-btn v-if="canEdit" icon @click="editTitleDialog = true">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <div class="center-all">
        <v-autocomplete
          :key="menus.length"
          v-if="isEmpty"
          @change="autocompleteHandler"
          :items="menus"
          :search-input.sync="search"
          :placeholder="$t('generics.pickMenu')"
          cache-items
          class="mx-4 mt-5"
          flat
          item-text="title"
          return-object
          hide-no-data
          hide-details
          solo-inverted
          style="max-width: 550px; border: 1px black solid"
        >
          <template v-slot:prepend-item
            ><div
              class="d-flex justify-space-between align-center px-4"
              style="width: 100%"
            >
              <span class="small-text">{{ $t('order.eraseAllMenus') }}</span>
              <v-btn icon @click.stop="destroyAll()">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div></template
          >
          <template v-slot:item="{ item }">
            <div
              class="d-flex justify-space-between align-center"
              style="width: 100%"
            >
              <span class="small-text">{{ item.title }}</span>
              <v-btn icon @click.stop="destroyMenu(item._id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template></v-autocomplete
        >
      </div>

      <v-data-table
        :headers="headers"
        :items="menu.products"
        id="orders-table"
        class="app-table mt-5"
        :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
        ref="table"
        mobile-breakpoint="0"
      >
        <template #body="props">
          <draggable
            v-if="$refs.table"
            tag="tbody"
            :list="menu.products"
            :options="{ handle: '.drag-icon' }"
          >
            <v-nodes :vnodes="$refs.table.genItems(props.items, props)" />
          </draggable>
        </template>
        <template v-slot:item.drag>
          <v-icon large>mdi-drag</v-icon>
        </template>
        <template v-slot:item.product.price="{ item }">
          {{ `${item.product.price} ₪` }}
        </template>
        <template v-slot:item.sum="{ item }">
          {{ `${item.product.price * item.quantity} ₪` }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex" v-if="canEdit">
            <v-icon @click="editProductHandler(item)">mdi-pencil</v-icon>
            <v-icon @click="removeProduct(item)">mdi-delete</v-icon>
          </div>
        </template>
      </v-data-table>
      <div
        v-if="canEdit"
        class="d-flex justify-center mt-10 align-center"
        style="gap: 10px; position: relative"
      >
        <my-btn :isSmall="true" @click="addProduct" />
        <div style="position: absolute; left: 0; bottom: 36px">
          <v-tooltip v-if="isSaved" bottom
            ><span>{{ $t('menu.saved') }}</span>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-content-save-check</v-icon>
            </template>
          </v-tooltip>
          <v-btn
            class="secondary ml-1"
            :disabled="!validated"
            v-if="canSave && !loading && !isSaved"
            @click="saveMenu"
            style="justify-self: "
          >
            {{ $t('menu.save') }}
          </v-btn>

          <v-progress-circular
            v-if="loading"
            indeterminate
            color="secondary"
            :size="20"
          />
        </div>
      </div>
      <div
        class="d-flex justify-center align-center flex-column mt-2 pt-2"
        style="gap: 5px; border-top: solid black 1px"
        v-if="calculateMenuPrice"
      >
        <div v-if="!isEmpty" class="ps-3" style="width: 100%"></div>

        <div class="d-flex align-center">
          <span style="width: 120px"> {{ $t('generics.sum') }}: </span>
          <span class="d-flex align-center" style="width: 120px; gap: 2px">
            {{ formateNumbers(calculateMenuPrice) }} ₪
          </span>
        </div>
        <div class="d-flex align-center">
          <span style="width: 120px"> {{ $t('bid.discountInPercent') }}: </span>
          <span class="d-flex align-center" style="width: 120px; gap: 2px">
            <span>%</span>
            <number-picker :max="100" :min="0" v-model="menu.discount" />
          </span>
        </div>
        <div class="d-flex" v-if="isRound">
          <span style="width: 120px"> {{ $t('bid.discountRemainder') }}: </span>
          <span style="width: 120px"> {{ calculateDiscount.rounded }} ₪ </span>
        </div>
        <div class="d-flex" v-if="menu.discount || isRound">
          <span style="width: 120px">{{ $t('bid.priceAfterDiscount') }}:</span
          ><span style="width: 120px"
            >{{ formateNumbers(+calculateDiscount.total) }} ₪</span
          >
        </div>
      </div>
    </v-card-text>
    <product-handler
      v-if="productDialog"
      v-model="productDialog"
      :isNew="isNewProduct"
      @createProduct="createProduct"
      :p="chosenProduct"
      @editProduct="editProduct"
    />
    <text-field-dialog
      :title="$t('menu.saveName')"
      :label="$t('generics.name')"
      :model="editTitleDialog"
      @model="editTitleDialog = $event"
      :value="menu.title"
      @input="menu.title = $event"
    />
  </v-card>
</template>
<script>
import _ from 'lodash'
import ProductHandler from './MenuProductHandler.vue'
import MyBtn from './generics/MyBtn.vue'
import TextFieldDialog from './dialogs/TextFieldDialog.vue'
import draggable from 'vuedraggable'
import NumberPicker from './generics/NumberPicker.vue'
import { calculateRounded } from '@/helpers/calculateRounded'
import { formateNumbers } from '@/helpers/formatter'

export default {
  name: 'menu-card',
  props: {
    canDelete: { type: Boolean, default: true },
    canSend: { type: Boolean, default: true },
    canSave: { type: Boolean, default: true },
    canEdit: { type: Boolean, default: true },
    client: { type: Object, default: () => ({}) },
    value: { type: Object, default: () => ({}) },
  },
  components: {
    ProductHandler,
    MyBtn,
    TextFieldDialog,
    draggable,
    NumberPicker,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      search: '',
      isSaved: false,
      isNew: true,
      key: null,
      loading: false,
      isNewProduct: false,
      productDialog: false,
      editTitleDialog: false,
      chosenProduct: {},

      headers: [
        {
          text: '',
          value: 'drag',
          sortable: false,
          class: 'drag-icon',
          cellClass: 'drag-icon',
        },
        {
          text: this.$t('generics.quantity'),
          value: 'quantity',
          sortable: false,
        },
        {
          text: this.$t('generics.product'),
          value: 'product.title',
          sortable: false,
        },
        {
          text: this.$t('product.price'),
          value: 'product.price',
          sortable: false,
        },
        {
          text: this.$t('generics.notes'),
          value: 'product.info',
          sortable: false,
        },
        { text: this.$t('generics.sum'), value: 'sum', sortable: false },
        {
          text: this.$t('generics.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 600
    },
    isRound() {
      return !['0.00 -', '0.00'].includes(this.calculateDiscount.rounded)
    },
    menu: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    menus() {
      return this.$store.getters.menus
    },
    isEmpty() {
      return this.menu.products.length <= 0
    },
    validated() {
      return !this.isEmpty && this.menu.title !== ''
    },
    calculateMenuPrice() {
      let price = 0
      this.menu?.products?.forEach(
        p => (price += this.calculateProductPrice(p))
      )
      return price
    },
    calculateDiscount() {
      const discount = this.menu?.discount ?? 0
      const amount = this.calculateMenuPrice ?? 0
      return calculateRounded(amount, discount)
    },
  },
  methods: {
    destroyAll() {
      this.$store.dispatch('menu/destroyAll')
    },
    destroyMenu(id) {
      this.$store.dispatch('menu/destroy', id)
    },
    autocompleteHandler(v) {
      const newMenu = _.cloneDeep(v)
      newMenu.title = this.menu.title
      newMenu.products.forEach(p => {
        p.isPicked = false
        p.isReady = false
      })
      this.menu = newMenu
    },
    showLinks() {
      this.$emit('showLinks', this.key)
    },
    destroy() {
      this.$emit('destroy', this.key)
    },
    async saveMenu() {
      this.loading = true
      // const menu = this.removeUnnecessaryData(this.menu)
      await this.$store.dispatch('menu/store', this.menu)
      this.loading = false
      this.isSaved = true
    },
    calculateProductPrice(p) {
      return p.product.price * p.quantity
    },
    formateNumbers,

    editProductHandler(p) {
      this.isNewProduct = false
      this.chosenProduct = p
      this.productDialog = true
    },
    addProduct() {
      this.isNewProduct = true
      this.productDialog = true
    },
    editProduct(p) {
      this.menu.products = this.menu.products.map(productInMap =>
        productInMap._id === p._id ? p : productInMap
      )
    },
    removeProduct(p) {
      this.menu.products = this.menu.products.filter(el => el._id !== p._id)
    },
    createProduct(p) {
      this.menu.products.push(p)
    },
    removeUnnecessaryData(menu) {
      menu.products = menu.products.map(p => {
        delete p.product?.catalogNumber
        // delete p._id
        return p
      })
      return menu
    },
  },
  mounted() {
    if (this.menu?.products?.length) {
      this.menu.products.push({})
      this.menu.products.pop()
    }
  },
}
</script>
